.invites{
  @media (max-width: $xxl) {
    padding-bottom: 100px;
  }
  @media (max-width: $lg) {
    padding-bottom: 80px;
  }
  @media (max-width: $sm) {
    padding-bottom: 60px;
  }
  .indicators__wrap{
    padding: 70px;
    @media (max-width: $xxl) {
      padding: 50px;
    }
    @media (max-width: $lg) {
      padding: 30px;
    }
    @media (max-width: $sm) {
      padding: 35px 20px 20px 20px;
    }
  }
  &__row{
    display: flex;
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &-content{
      flex: 1;
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
      }
      &__title{
        font-size: 60px;
        line-height: 75px;
        @media (max-width: $xxl) {
          font-size: 40px;
          line-height: 50px;
        }
        @media (max-width: $sm) {
          font-size: 26px;
          line-height: 33px;
          text-align: center;
        }
      }
      &__text{
        margin-top: 40px;
        font-size: 16px;
        line-height: 30px;
        @media (max-width: $xxl) {
          font-size: 14px;
          line-height: 24px;
        }
        @media (max-width: $lg) {
          margin-top: 20px;
        }
        @media (max-width: $sm) {
          text-align: center;
           
          li {
            &::before {
              display: none !important;
            }
          }
        }
      }
      &__button{
        margin-top: 60px;
        @media (max-width: $xxl) {
          margin-top: 40px;
        }
        @media (max-width: $lg) {
          margin-top: 20px;
        }
        @media (max-width: $sm) {
          justify-content: center;
          display: flex;
        }
        .btn{
          padding: 20px;
          line-height: 20px;
          @media (max-width: $xxl) {
            padding: 14px 20px;
            line-height: 140%;
          }
          svg{
            margin-left: 10px;
          }
        }
      }
    }
    &-image{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
        justify-content: center;
        margin-top: 40px;
      }
      img{
        @media (max-width: $xxl) {
          max-width: 360px;
        }
        @media (max-width: $lg) {
          max-width: 240px;
        }
        @media (max-width: $sm) {
          max-width: 100%;
        }
      }
    }
  }
}