.get-a-report {
  padding: 40px;
  text-align: center;

  @media (max-width: $lg ) {
    @media (min-width: $sm) {
      padding: 30px 30px 40px;
      text-align: left;
      display: grid;
      grid-template-columns: 50px 1fr 210px;
      grid-template-areas: 
        "icon title btn"
        "icon desc btn"
      ;
      align-items: center;
      column-gap: 15px;
    }
  }

  @media (max-width: $xxl ) {
    padding: 30px 15px 15px;
  }

  @media (max-width: $sm) {
    padding: 30px 20px 20px;
  }

  &__icon {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-grey-bgr);
    border-radius: 50%;

    @media (max-width: $xxl ) {
      height: 40px;
      width: 40px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    @media (max-width: $lg ) {
      @media (min-width: $sm) {
        height: 50px;
        width: 50px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-wrap {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;

      @media (max-width: $xxl ) {
        margin-bottom: 15px;
      }

      @media (max-width: $lg ) {
        @media (min-width: $sm) {
          margin-bottom: 0;
          grid-area: icon;
        }
      }
    }
  }

  &__title {
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }

    @media (max-width: $lg ) {
      @media (min-width: $sm) {
        grid-area: title;
      }
    }
  }

  &__desc {
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }

    @media (max-width: $lg ) {
      @media (min-width: $sm) {
        margin-bottom: 0;
        grid-area: desc;
      }
    }
  }

  &__btn {
    @media (max-width: $lg ) {
      @media (min-width: $sm) {
        grid-area: btn;
      }
    }

    a {
      width: 200px;

      @media (max-width: $xxl ) {
        width: 100%;
        line-height: 48px;
      }

      @media (max-width: $lg ) {
        width: 210px;
      }

      @media (max-width: $sm) {
        width: 100%;
        max-width: 210px;
      }
    }
  }
}