.about{
  padding: 100px 0;
  position: relative;
  height: auto;
  @media (max-width: $xxl) {
    padding: 80px 0;
  }
  @media (max-width: $sm){
    padding: 60px 0;
  }
  .count--number-page{
    @media (max-width: $sm){
      margin-left: auto;
      margin-right: auto;
    }
  }
  &:after{
    position: absolute;
    content: 'channelchek';
    display: block;
    top: 37%;
    left: 33%;
    font-family: $Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 320px;
    line-height: 100%;
    color: #F6F6F6;
    z-index: 1;
    @media (max-width: $xxl) {
      font-size: 250px;
      line-height: 100%;
    }
    @media (max-width: $lg){
      display: none;
    }
  }
  &__wrap{
    position: relative;
    &:after{
      background: $blue;
      width: 100%;
      height: 10px;
      display: block;
      content: '';
      position: absolute;
      bottom: -100px;
      left: 0;
      margin-top: 100px;
      @media (max-width: $sm){
        margin-top: 0;
        bottom: -60px;
      }
    }
  }
  &__line{
    &:after,&:before {
      top: 0;
      left: 50%;
      position: absolute;
      height: 100%;
      width: 1px;
      background: $grey_line;
      content: '';
      display: block;
      z-index: 2;
      @media (max-width: $sm){
        display: none;
      }
    }
      &:after{
        left: 75%;
      }
    &:first-child{
      &:before{
        display: none;
      }
      &:after{
        left: 25%;
      }
    }

  }
  &__content{
    position: relative;
    z-index: 2;
    margin-top: 40px;
    @media (max-width: $lg){
      margin-top: 15px;
    }
    &-title{
      margin-bottom: 60px;
      width: 50%;
      @media (max-width: $xxl) {
        margin-bottom: 20px;
      }
      @media (max-width: $lg){
        margin-bottom: 15px;
      }
      @media (max-width: $sm){
        width: 100%;
        text-align: center;
      }
      br{
        @media (max-width: $sm){
          display: none
        }
      }
      span{
        @media (max-width: $sm){
          margin-left: 5px;
        }
      }
    }
    &-text{
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      max-width: 440px;
      @media (max-width: $xxl) {
        font-size: 14px;
        line-height: 24px;
        max-width: 330px;
      }
      @media (max-width: $lg){
        max-width: 340px;
      }
      @media (max-width: $sm){
        max-width: 100%;
        text-align: center;
      }
      &__button{
        margin-top: 60px;
        @media (max-width: $xxl) {
          margin-top: 40px;
        }
        @media (max-width: $sm) {
          display: flex;
          justify-content: center;
        }
        .btn{
          padding: 20px;
          line-height: 140%;
          @media (max-width: $xxl) {
            padding: 14px 20px;
          }
        }
      }
      &--1{
        margin-bottom: 40px;
        @media (max-width: $xxl) {
          margin-bottom: 20px;
        }
      }
      &--2{
        padding-left: 360px;
        max-width: 770px;
        @media (max-width: $xxl) {
          padding-left: 285px;
          max-width: 725px;
        }
        @media (max-width: $lg){
          padding-left: 0;
          max-width: 340px;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: $sm){
          display: none;
        }
      }
      &--3{
        padding-left: 51%;
        max-width: calc(51% + 440px);
        margin-top: 40px;
        @media (max-width: $xxl) {
          max-width: calc(51% + 330px);
        }
        @media (max-width: $lg) {
          max-width: calc(51% + 340px);
        }
        @media (max-width: $sm) {
          display: none;
          max-width: 100%;
          padding-left: 0;
        }
      }
      &--mobile{
        display: none;
        @media (max-width: $sm){
          display: block;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  &__images{
    @media (max-width: $sm){
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    &-image{
      position: absolute;
      top: 0;
      z-index: 2;
      @media (max-width: $xxl) {
        max-width: 240px;
      }
      @media (max-width: $lg){
        max-width: 160px;
      }
      @media (max-width: $sm){
        position: relative;
        max-width: 46%;
      }
      &--1{
        left: 51%;
        @media (max-width: $sm){
          left: 0;
        }
      }
      &--2{
        top: 80px;
        left: 76%;
        @media (max-width: $sm){
          top: 0;
          left: 0;
          margin-top: 20px;
        }
      }
      &--3{
        top: auto;
        bottom: 0;
        left: 0;
      }
    }
  }
  &__circle{
    display: block;
    position: relative;
    border: 1px solid $orange;
    border-radius: 50%;
    padding: 7px;
    cursor: pointer;
    z-index: 2;
    @media (max-width: $sm){
      width: 120px;
      height: 120px;
      margin-left: auto;
      margin-right: auto;
    }
    svg{
      fill: $orange;
    }
    .icon-circle-text{
      @media (max-width: $xxl) {
        width: 120px;
        height: 120px;
      }
      &:hover{
        animation:spin 15s linear infinite;
      }
    }
    .icon-arrow{
      position: absolute;
      left: 42%;
      top: 44%;
    }
    &-wrap{
      position: absolute;
      bottom: 0;
      right: 0;
      @media (max-width: $xxl) {
        bottom: -20px;
      }
      @media (max-width: $sm){
        position: relative;
        bottom: 0;
      }
    }
  }
  &--discover{
    &:after{
      content: 'Discover';
    }
    .about{
      &__content{
        &-text{
          &--1{
            padding-left: 26%;
            max-width: calc(440px + 26%);
            @media (max-width: $xxl) {
              max-width: calc(330px + 26%);
            }
            @media (max-width: $lg){
              max-width: 340px;
              padding-left: 0;
            }
            @media (max-width: $lg){
              max-width: 100%;
            }
          }
          &--2{
            padding-left: 51%;
            max-width: calc(440px + 51%);
            @media (max-width: $xxl) {
              max-width: calc(330px + 51%);
            }
            @media (max-width: $lg){
              max-width: calc(340px + 26%);
              padding-left: 26%;
              margin-left: 0;
            }
            @media (max-width: $sm){
              padding-left: 0;
              max-width: 100%;
            }
          }
        }
      }
      &__images{
        &-image{
          &--1{
            left: 0;
            bottom: 0;
            top: auto;
            @media (max-width: $lg){
              top: 50px;
              bottom: auto;
              left: auto;
              right: 26%;
            }
            @media (max-width: $sm){
              top: 0;
              right: 0;
            }
          }
          &--2{
            top: 0;
            @media (max-width: $lg){
              top: 80px;
            }
            @media (max-width: $sm){
              top: 0;
            }
          }
        }
      }
    }
  }
  &--noblecon{
    &:after{
      content: 'NobleCon';
    }
    .about{
      &__content{
        padding-bottom: 169px;
        @media (max-width: $xxl) {
          padding-bottom: 0;
        }
      }
      &__images{
        &-image{
          &--1{
            top: auto;
            bottom: 0;
            @media (max-width: $lg) {
              bottom: auto;
              top: 80px;
            }
            @media (max-width: $sm) {
              top: 0;
            }
          }
          &--2{
            top: 0;
          }
        }
      }
    }
  }
  &--college{
    &:after{
      content: 'College';
    }
    .about{
      &__content{
        &-text{
          &--2{
            @media (max-width: $xxl) {
              margin-top: 55px;
            }
            @media (max-width: $lg) {
              margin-top: 63px;
            }
          }
        }
      }
      &__images{
        @media (max-width: $sm) {
          flex-wrap: wrap;
        }
        &-image{
          &--3{
            @media (max-width: $sm) {
              display: none;
            }
          }
        }
      }
    }
  }
  &--offerings{
    &:after{
      content: 'Offerings';
    }
    .about{
      &__images{
        &-image{
          &--1{
            bottom: 0;
            top: auto;
            @media (max-width: $xxl) {
              bottom: auto;
              top: 80px;
            }
            @media (max-width: $sm) {
              top: 0;
              margin-top: 55px;
            }
          }
          &--2{
            top: 0;
            @media (max-width: $sm) {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  &--noble-related{
    &:after{
      content: 'Noble C';
      @media (max-width: $xxl){
        content: 'Noble';
      }
    }
    .about{
      &__images{
        &-image{
          &--1{
            top: auto;
            bottom: 0;
            @media (max-width: $xxl) {
              bottom: auto;
              top: 80px;
            }
            @media (max-width: $sm){
              top: 0;
              margin-top: 44px;
            }
          }
          &--2{
            top: 0;
            @media (max-width: $sm) {
              margin-top: 29px;
            }
          }
          &--noble{
            right: 0;
            bottom: 0;
            top: auto;
            @media (max-width: $xxl) {
              width: 127px;
            }
            @media (max-width: $lg) {
              display: none;
            }
          }
        }
      }
    }
  }
}
@keyframes spin { 100% { transform:rotate(360deg); } }