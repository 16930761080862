.footer {
  background: var(--light-grey-bgr);
  padding: 40px 0;
  transition: background .2s ease-in-out;

  @media (max-width: $lg ) {
    padding: 30px 0;
  }
  
  @media (max-width: $sm ) {
    padding: 40px 0;
  }

  &-scroll-up {
    display: flex;
    align-items: center;
    transition: color .2s ease-in-out;
    color: var(--black);
    
    svg {
      margin-right: 10px;
      height: 20px;
      width: 17px;
    }

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
        text-decoration: none;
      }
    }
  }

  &-menu {
    margin: 40px 0;
    padding: 40px 0;
    border-top: 1px solid var(--light-grey-line);
    border-bottom: 1px solid var(--light-grey-line);

    @media (max-width: $lg ) {
      margin: 30px 0;
      padding: 30px 0;
    }
    
    @media (max-width: $sm ) {
      margin: 40px 0;
      padding: 40px 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      @media (max-width: $sm) {
        grid-template-columns: 1fr;
      }

      @media (max-width: $sm) {
        gap: 5px;
      }
    }

    li {
      @media (max-width: $sm) {
        text-align: center;
      }
    }

    a {
      font-family: $Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43em;
      position: relative;
      transition: color .2 ease-in-out;
      color: var(--black);

      @media (max-width: $lg ) {
        font-size: 12px;
        line-height: 0.166em;
      }

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 0;
        height: 1px;
        background: currentColor;
        transition: width .2s ease-in-out;
      }

      &:hover {
        @media (min-width: $md) {
          color: var(--violet);
          text-decoration: none;
        }
      }
    }
  }

  &-head {
    &__col {
      @media (max-width: $sm) {
        margin-bottom: 20px;
        
        &:last-child {
          margin-bottom: 0;
        }

        &--social {
          order: 1
        }

        &--logo {
          order: 0
        }

        &--btns {
          order: 2
        }
      }
    }
  }
}