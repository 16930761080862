.reset-popup {
  padding: 60px 40px;
  width: calc(100vw - 40px);
  max-width: 520px;
  min-height: 100px;

  @media (max-width: $xxl ) {
    padding: 30px;
    padding-bottom: 40px;
  }

  @media (max-width: $sm) {
    width: 100vw;
    max-width: none;
  }
}