.cc-inner-page-hero-section {
  &__row {
    @media (max-width: $sm) {
      --column-gap: 10px;
    }
  }

  &__wrap {
    border-bottom: 1px solid var(--black);
    padding: 45px 0;

    @media (max-width: $xxl ) {
      padding: 30px 0;
    }

    @media (max-width: $sm) {
      padding: 20px 0;
    }
  }

  &-title-wrap {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: var(--column-gap);

    &__icon {
      align-self: center;
    }

    &__desc, &__text {
      white-space: nowrap;
    }
  } 
}