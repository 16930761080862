.filter-btn {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
  color: var(--black);

  @media (max-width: $xs) {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    line-height: 36px;
    background: var(--light-grey-bgr);
    border-radius: 8px;
    margin-top: 20px;

    &__text {
      margin-left: 10px;
    }
  }
}