.community{
  @media (max-width: $xxl) {
    padding-top: 100px;
    padding-bottom: 0;
  }
  @media (max-width: $sm) {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .indicators__wrap{
    padding: 70px 50px;
    @media (max-width: $xxl) {
      padding: 50px;
    }
    @media (max-width: $lg){
      padding: 50px 30px 30px 30px;
    }
    @media (max-width: $sm) {
      padding: 35px 20px 20px 20px;
    }
  }
  h2{
    @media (max-width: $sm) {
      text-align: center;
    }
  }
}