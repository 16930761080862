.login-popup {
  max-width: 920px;
  width: calc(100vw - 40px);
  min-height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $sm ) {
    grid-template-columns: 1fr;
    width: 100vw;
  }

  &__head {
    padding: 40px;
    padding-bottom: 0;
    background: var(--light-grey-bgr);
    display: flex;
    flex-direction: column;

    @media (max-width: $xxl ) {
      padding: 30px;
      padding-bottom: 0;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__img {
    flex: 1 1;
    display: block;
    margin: 0 auto;
    object-fit: contain;
    object-position: center bottom;

    @media (max-width: $xxl ) {
      width: 100%;
      max-width: 235px;
    }
  }

  &__body {
    padding: 40px;
    
    @media (max-width: $xxl ) {
      padding: 30px;
    }
  }
}