.popup--filter {
  left: auto;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: none;
  transform: translate(100%, -50%);
  transition: all .2s ease-in-out;

  @media (max-width: $xs) {
    max-width: none;
    transform: translateY(-60%);
  }

  &.popup--open {
    @media (max-width: $lg) {
      transform: translateY(-50%);
    }
  }
}

.filter-popup {
  height: 100%;
  padding: 50px 40px 100px 20px;

  @media (max-width: $xs) {
    padding: 50px var(--container-side-padding) 100px;
  }
}