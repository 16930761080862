.popup-search {
  position: absolute;
  top: calc(100% + 1px);
  left: 50%;
  width: 100vw;
  height: 60px;
  display: none;
  box-shadow: 0px 5px 10px rgba(29, 29, 29, 0.15);
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%);
  transition: opacity .3s linear;

  @media (max-width: $sm) {
    opacity: 1;
    pointer-events: all;
  }

  &__field {
    border-radius: 0;
    border: none;
    height: 100%;
    width: 100%;

    @media (max-width: $sm) {
      font-size: 18px;
    }
  }

  &__btn {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--grey);
  }
}