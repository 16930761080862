.search-results {
  position: fixed;
  top: 79px;
  left: 50%;
  width: 100%;
  max-width: var(--container-width);
  transform: translateX(-50%);
  z-index: 10;
  padding: 20px;
  padding-top: 0;
  display: none;
  grid-template-columns: 1fr;
  gap: 20px;
  transition: top .2s ease-in-out;

  @media (max-width: $xxl ) {
    top: 66px;
    gap: 15px;
    padding: 15px;
    padding-top: 0;
  }

  @media (max-width: $sm) {
    max-width: 100%;
    top: 127px;
    height: calc(100vh - 127px);
    overflow-x: scroll;
    padding-bottom: 100px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    border-bottom: 1px solid var(--light-grey-line);
    border-top: 1px solid var(--light-grey-line);
    margin-bottom: -1px;
  }

  &__btn {
    @media (max-width: $sm) {
      display: none;
    }
  }
}