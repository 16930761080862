.social-list-analyst{
  display: flex;
  align-items: flex-end;
  list-style: none;
  justify-content: end;
  height: 100%;
  padding-left: 0;
  @media (max-width: $xxl) {
  }
  @media (max-width: $sm) {
    justify-content: center;
  }
  &__item{
    border: 1px solid #E8E8E8;
    border-radius: 50%;
    margin-right: 10px;
    transition: all .3s ease;
    &--twitter{
      &:hover{
        border-color: #1D9BF0;
        svg{
          fill:#1D9BF0;
        }
      }
    }
    &--linkedin{
      &:hover{
        border-color: #0966C3;
        svg{
          fill:#0966C3;
        }
      }
    }
    &--facebook{
      &:hover{
        border-color: #1577F1;
        svg{
          fill:#1577F1;
        }
      }
    }
    &--mail{
      &:hover{
        border-color: $yellow;
        svg{
          fill:$yellow;
        }
      }
    }
    @media (max-width: $xxl) {
      margin-right: 5px;
    }
    &:last-child{
      margin-right: 0;
    }
  }
  &__link{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      width: 30px;
      height: 30px;
    }
    svg{
      width: 15px;
      height: 15px;
      transition: all .3s ease;
    }
  }
}