.cc-related-news-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__box {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__img {
    margin-bottom: 15px;
    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }

    * {
      width: 100%;
      @media (max-width: $sm) {
        max-height: 236px;
      }
    }
  }

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  &__desc {
    margin-bottom: 20px;
    flex: 1 1;

    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }

    @media (max-width: $sm) {
      display: none;
    }
  }

  &--post {
    .cc-related-news-card {
      &__desc {
        @media (max-width: $sm) {
          display: block;
        }
      }
    }
  }
}