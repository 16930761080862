.btn-more {
  --btn-more-circle-size: 4px;

  display: inline-grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, var(--btn-more-circle-size));
  gap: var(--btn-more-circle-size);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: var(--light-grey-bgr);
  transition: all .2s ease-in-out;

  @media (max-width: $xxl ) {
    --btn-more-circle-size: 3px;

    height: 30px;
    width: 30px;
  }

  span {
    display: block;
    width: var(--btn-more-circle-size);
    height: var(--btn-more-circle-size);
    background: var(--blue);
    border-radius: 50%;
    transition: all .2s ease-in-out;
  }

  &:hover {
    @media (min-width: $md) {
      background: var(--violet);
      span {
        background: var(--white);
      }
    }
  }

  &--active {
    @media (min-width: $md) {
      span {

        &:nth-child(1){animation: bounceJump .4s ease-in-out}
        &:nth-child(2){animation: bounceJump .3s .1s ease-in-out}
        &:nth-child(3){animation: bounceJump .2s .2s ease-in-out}
      }
    }
  }
}

@keyframes bounceJump {
  0% {transform: translateY( 0px )}
  50% {transform: translateY( -5px )}
  100% {transform: translateY( 0px )}
}