.homepage-cc-in-main-card {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;  

    &:hover {
      @media (min-width: $md) {
        & + .homepage-cc-in-main-card__box {
          .homepage-cc-in-main-card {
            &__title {
              color: var(--yellow);
              text-decoration: underline;
            }
  
            &__img-box::after {
              border-width: 10px;
            }
          }
        }
      }
    }
  }

  &__box {
    flex: 1 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $sm) {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--light-grey-line);
    }
  }

  &__title {
    transition: all .2s ease-in-out;
  }

  &__img {
    @media (max-width: $sm) {
      max-height: 320px;
      object-fit: cover;
    }

    @media (max-width: $xs) {
      max-height: 180px;
    }
  }

  &__img-box {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0px solid var(--yellow);
      transition: all .2s ease-in-out;
      box-sizing: border-box;
    }
  }

  &__desc {
    flex: 1 1;
  }
}