.analyst-profiles{
  &__head{
    display: flex;
    align-items: center;
    @media (max-width: $sm){
      display: block;
    }
    .count--number-page{
      @media (max-width: $sm){
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-title{
      margin-left: 20px;
      @media (max-width: $sm){
        margin-left: 0;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
  &__list{
    margin-top: 80px;
    @media (max-width: $xxl) {
      margin-top: 55px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }
}