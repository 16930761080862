.btn-left-icon {
  display: flex;
  align-items: center;
  font-family: $Inter;
  font-weight: 500;
  font-size: 14px;
  color: var(--black);
  transition: color .2s ease-in-out;

  @media (max-width: $xxl ) {
    font-size: 12px;
  }

  &:hover {
    @media (min-width: $md) {
      color: var(--violet);
      text-decoration: none;
    }
  }

  svg {
    display: block;
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  span {
    color: currentColor;
  }
}