.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 150px));
  opacity: 0;
  z-index: 100;
  transition: opacity .2s ease-in-out, transform .0s .2s;
  pointer-events: none;
  border-top: 5px solid var(--orange);
  background: var(--white);
  max-height: calc(100vh - 40px);
  overflow-x: auto;

  @media (max-width: $sm) {
    min-height: 100%;
  }

  & > * {
    max-width: 100%;
  }

  &-step {
    max-width: 520px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $xxl ) {
      max-width: 270px;
    }

    @media (max-width: $sm) {
      max-width: 400px;
    }
  }

  &-close-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: var(--black);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s linear;
  }

  &-open {
    .popup {
      &-close-wrap {
        opacity: 0.5;
        pointer-events: all;
      }
    }

    &--filter-popup {
      .popup {
        &-close-wrap {
          @media (min-width: $lg ) {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  &--open {
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 101;
    pointer-events: all;
    transition: all .2s ease-in-out;
  }

  &-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    transition: color .2s ease-in-out;

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
      }
    }
  }

  &-icon {
    width: 60px;
    height: 60px;
    background: var(--light-grey-bgr);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;

    svg {
      height: 24px;
      width: 24px;
    }

    @media (max-width: $xxl ) {
      width: 40px;
      height: 40px;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

@import "search-results";
@import "login-popup";
@import "create-popup";
@import "reset-popup";
@import "filter-popup";
@import "confirmation-agreement";