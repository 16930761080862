.video-hover {
  &-enter {
    @media (min-width: $md) {
      .cc-play-btn {
        &::before {
          background: var(--turquoise);
          opacity: 1;
        }
      }
    }
  }
}