.table{
  font-family: $Inter;
  font-size: 14px;
  line-height: 20px;
  @media (max-width: $xxl) {
    font-size: 12px;
  }
  &--2-col{
    .table__column{
      width: 50%;
      @media (max-width: $xxl) {
        font-size: 12px;
      }
    }
  }
  &--related{
    @media (max-width: $sm) {
      display: flex;
      flex-wrap: wrap;
    }
    .table{
      &__row{
        border-bottom: 1px solid $grey_line;
        @media (max-width: $sm) {
          display: flex;
          flex: auto;
          width: 60%;
          flex-wrap: wrap;
          border-bottom: none;
          padding: 0;
        }
        &:nth-child(odd){
          background: transparent;
        }
        &--head{
          border-bottom: 1px solid #1D1D1D;
          font-weight: 500;
          @media (max-width: $sm) {
            width: 40%;
            border-bottom: none;
            display: flex;
            flex-wrap: wrap;
            flex: auto;
            margin-bottom: 5px;
          }
          display: none;
          &:first-child{
            display: flex;
          }
          .table{
            &__column{
              padding-top: 20px;
              padding-bottom: 20px;
              @media (max-width: $xxl) {
                padding-top: 15px;
                padding-bottom: 15px;
              }
              @media (max-width: $sm) {
                flex: auto;
                width: 100%;
                padding: 0;
                margin-bottom: 5px;
              }
              &:first-child{
                @media (max-width: $sm){
                  display: none;
                }
              }
            }
          }
        }
        &--body{
          @media (max-width: $sm){

          }
          .table__column{
            @media (max-width: $sm){
              margin-bottom: 5px;
            }
            &:first-child{
              @media (max-width: $sm){
                display: none;
              }
            }
          }
        }
        &--advanced{
          @media (max-width: $sm) {
            width: 100%;
            margin-top: 10px;
            border-top: 1px dashed $grey_line;
            border-bottom: 1px solid $grey_line;
            padding-top: 15px;
            flex-wrap: wrap;
            padding-bottom: 15px;
          }
          .table{
            &__column{
              flex: 1;
              @media (max-width: $sm){
                flex: auto;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
              }
              h6{
                line-height: 20px;
                margin-right: 5px;
                @media (max-width: $lg) {
                  font-size: 12px;
                }
                @media (max-width: $sm){
                  width: auto;
                }
              }
              .rating{
                margin-left: 20px;
                @media (max-width: $xxl) {
                  margin-left: 15px;
                }
                @media (max-width: $sm){
                  width: 100%;
                  margin-left: 0;
                  display: flex;
                  margin-top: 5px;
                  margin-bottom: 15px;
                }
                span{
                  font-weight: 500;
                  margin-left: 5px;
                  @media (max-width: $lg) {
                    margin-left: 0;
                    display: block;
                  }
                  @media (max-width: $sm){
                    margin-left: 5px;
                  }
                }
              }
              .btn-primary{
                border-radius: 8px;
                padding: 10px 20px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                font-family: $Inter;
                margin-right: 15px;
                @media (max-width: $xxl) {
                  font-size: 12px;
                  padding: 8px 20px;
                }
              }
              &:first-child{
                flex: 1;
                .favorites{
                  @media (max-width: $sm) {
                    display: none;
                  }
                }
              }
              &:last-child{
                display: flex;
                justify-content: flex-end;
                @media (max-width: $sm){
                  justify-content: space-between;
                }
                .favorites{
                  display: none;
                  @media (max-width: $sm){
                    display: inline-block;
                  }
                }
              }
              .stars{
                svg{
                  width: 16px;
                  min-width: 16px;
                }
              }
            }
          }
        }
        &--mobile{
          display: none;
          @media (max-width: $sm){
            display: block;
            width: 100%;
            padding-top: 20px;
            border-top: 1px solid $grey_line;
            margin-bottom: 15px;
          }
          .table{
            &__column{
              @media (max-width: $sm){
                width: 100%;
                padding: 0;
              }
              &-link{
                @media (max-width: $sm) {
                  font-size: 16px;
                  line-height: 20px;
                  font-family: $Merr;
                }
              }
            }
          }
        }
      }
      &__column{
        display: flex;
        flex: 1;
        align-items: center;
        padding-top: 25px;
        padding-bottom: 25px;
        @media (max-width: $xxl) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        @media (max-width: $sm) {
          width: 100%;
          flex: auto;
          padding: 0;
        }
        &:first-child{
          flex: 4;
          @media (max-width: $xxl) {
            flex: 3;
          }
          @media (max-width: $lg) {
            flex: 2;
          }
          @media (max-width: $sm) {
            width: 100%;
            flex: auto;
          }
        }
        &-logo{
          display: block;
          margin-right: 12px;
          @media (max-width: $xxl) {
            max-width: 27px;
            margin-right: 10px;
          }
          @media (max-width: $lg) {
            display: none;
          }
        }
        &-link{
          text-decoration-line: underline;
          color: $blue;
        }
        .favorites{
          margin-right: 20px;
          @media (max-width: $xxl) {
            margin-right: 17px;
          }
          svg{
            margin-right: 0;
            @media (max-width: $xxl) {
              min-width: 16px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  &--events{
    font-weight: 400;
    .table{
      &__row{
        padding: 0 15px;
        @media (max-width: $sm) {
          padding: 0;
        }
        &:nth-child(odd){
          background: transparent;
        }
        &--head{
          font-weight: 500;
        }
        border-bottom: 1px solid $grey_line;
      }
      &__column{
        &:first-child{
          width: 30%;
          @media (max-width: $sm) {
            width: 25%;
          }
        }
        padding: 20px 0;
        width: 70%;
        @media (max-width: $sm) {
          width: 75%;
        }
        &-link{
          color: $blue;
          transition: all .3s ease;
          &:hover{
            text-decoration: underline;
            color: $orange;
          }
        }
      }
    }
  }
  &--filings{
    @media (max-width: $sm) {
      display: flex;
      flex-wrap: wrap;
    }
    .table{
      &__row{
        border-bottom: 1px solid $grey_line;
        align-items: center;
        padding: 0;
        @media (max-width: $sm) {
          width: 70%;
          flex-wrap: wrap;
          align-items: flex-start;
        }
        &:nth-child(odd){
          background: transparent;
        }
        &--head{
          display: none;
          &:first-child{
            display: flex;
          }
          @media (max-width: $sm) {
            display: flex;
            width: 30%;
          }
          .table__column{
            padding: 20px 0;
          }
        }
      }
      &__column{
        padding: 15px 0;
        flex: 1;
        align-items: center;
        @media (max-width: $sm) {
          flex: auto;
          padding: 20px 0;
          width: 100%;
        }
        &:last-child{
          flex: 2;
        }
        &:nth-child(2){
          flex: 3;
        }
      }
    }
  }
  &__row{
    display: flex;
    padding: 0 20px;
    @media (max-width: $xxl) {
      padding: 0 10px;
    }
    &:nth-child(odd){
      background: $grey-light;
    }
    &--head{
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }
  }
  &__column{
    padding: 10px 0;
    @media (max-width: $xxl) {
      padding: 5px 0;
    }
    svg{
      margin-right: 6px;
    }
    &-link{
      color: $blue;
      text-decoration: underline;
    }
    &-view{
      display: flex;
      align-items: center;
      &-link{
        margin-right: 5px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}