.user-image {
  &.file-field--none {
    .user-image__delete {
      display: none;
    }
  }

  &__box {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 40px;
    align-items: center;

    &:hover {
      @media (min-width: $md) {
        .user-image__upload {
          text-decoration: underline;
        }
      }
    }
  }

  &__delete {
    margin-right: 20px;

    &:hover {
      @media (min-width: $md) {
        text-decoration: underline;

        & + .user-image__upload {
          text-decoration: none;
        }
      }
    }
  }

  &__wrap {
    display: flex;
  }
}