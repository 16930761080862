.affiliates{
  padding-bottom: 100px;
  @media (max-width: $xxl) {
    padding-bottom: 80px;
  }
  @media (max-width: $sm) {
    padding-bottom: 40px;
  }
    &__list{
      margin-top: 80px;
      @media (max-width: $xxl) {
        margin-top: 45px;
      }
      @media (max-width: $lg) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
}