.coverage-list{
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
  }
  &__item{
    position: relative;
    transition: all .3s ease;
    border: 10px solid transparent;
    &:after{
      display: block;
      content: '';
      width: calc(100% - 20px);
      height: 1px;
      background: #E8E8E8;
      position: absolute;
      left: 0;
      bottom: -1px;
      @media (max-width: $xxl) {
        width: calc(100% - 15px);
      }
    }
    &:before{
      display: block;
      content: '';
      width: 1px;
      background: #E8E8E8;
      position: absolute;
      right: -1px;
      bottom: 20px;
      height: calc(100% - 20px);
      @media (max-width: $xxl) {
        height: calc(100% - 15px);
        bottom: 15px;
      }
    }
    &:nth-child(even){
      &:before{
        @media (max-width: $sm) {
          content: none;
        }
      }
    }
    &:nth-child(4n+4){
      &:before{
        content: none;
      }
    }

    &:hover{
      border: 10px solid $blue_2;
      &:after,&:before{
        content: none;
      }
      .coverage-list__image{
        mix-blend-mode: normal;
      }
    }
  }
  &__link{
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    @media (max-width: $xxl) {
      height: 172px;
      padding-bottom: 15px;
    }
  }
  &__image{
    max-width: 120px;
    mix-blend-mode: luminosity;
    display: block;
    margin: 0 auto;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      max-width: 112px;
    }
    @media (max-width: $sm) {
      max-width: 100px;
    }
  }
  h5{
    text-align: center;
    position: absolute;
    bottom: 20px;
    font-weight: 400;
    color: $black;
    @media (max-width: $xxl) {
      bottom: 15px;
    }
  }
}