.cc-news-small-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__img {
    margin-bottom: 10px;

    * {
      width: 100%;
    }

    img {
      max-height: 146px;
      width: 100%;
    }
  }

  &__title {
    margin: 0;
    flex: 1 1;
    @media (max-width: $lg ) {
      margin: 10px 0;
    }
  }
}