@mixin select-list-card() {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--black);
  text-decoration: none;
  transition: background .2s ease-in-out;

  svg {
    fill: var(--grey);
    transition: fill .2s ease-in-out;
    margin-left: 15px;
  }

  &:hover {
    @media (min-width: $md) {
      text-decoration: none;
      background: var(--light-grey-bgr);

      svg {
        fill: var(--violet);
      }
    }
  }
}

.select-list {
  display: flex;
  border: 1px solid var(--light-grey-line);
  border-radius: 8px;
  height: 410px;

  @media (max-width: $xs) {
    flex-direction: column;
  }

  &__search {
    position: relative;
    margin: 20px;
    margin-bottom: 5px;

    &-field {
      padding-left: 40px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      color: #969696;
      pointer-events: none;
    }
  }

  &__items {
    border-right: 1px solid var(--light-grey-line);
    flex: 1 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $xs) {
      border-right: none;
      border-bottom: 1px solid var(--light-grey-line);
    }

    &-item {
      @include select-list-card();
    }
  }

  &__wrap {
    flex: 1 1;
  }

  &__selected {
    flex: 1 1;
    padding: 5px 0;
    display: flex;
    flex-direction: column;

    &-item {
      @include select-list-card();
    }
  }
}