.main-menu {
  width: 100%;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      &:nth-child(1) {--current-color: var(--orange)}
      &:nth-child(2) {--current-color: var(--blue)}
      &:nth-child(3) {--current-color: var(--violet)}
      &:nth-child(4) {--current-color: var(--turquoise)}
      &:nth-child(5) {--current-color: var(--green)}
      &:nth-child(6) {--current-color: var(--violet)}
      &:nth-child(7) {--current-color: var(--green)}
    }
  }

  a {
    font-weight: 500;
    font-family: $Inter;
    text-transform: uppercase;
    position: relative;
    transition: color .2s ease-in-out;
    color: var(--black);

    @media (max-width: $xxl ) {
      font-size: 14px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -17px;
      left: 0;
      width: 0;
      height: 2px;
      background: currentColor;
      transition: width .2s ease-in;
    }

    &:hover {
      @media (min-width: $md) {
        color: var( --current-color);
        text-decoration: none;
  
        &::before {
          width: 100%;
        }
      }
    }
  }
}