.table-dropdown {
  --cc-table-dropdown-offset: 67px;

  position: fixed;
  top: var(--cc-table-dropdown-offset);
  left: 50%;
  width: 100%;
  max-width: calc( var(--container-width) - 80px);
  transform: translateX(-50%);
  z-index: 4;
  pointer-events: none;
  overflow: hidden;
  padding: 0 40px;
  box-sizing: content-box;
  display: none;
  
  @media (min-width: $lg ) {
    visibility: hidden;
  }
  

  @media (max-width: $lg ) {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  @media (max-width: $sm) {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  }

  @media (max-width: $less) {
    --cc-table-dropdown-offset: 61px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    @media (max-width: $sm) {
      overflow-x: auto;
      height: calc(100vh - var(--cc-table-dropdown-offset) - 5px);
    }
  }

  &__head,
  &__foot {
    display: none;

    @media (max-width: $sm) {
      display: block;
    }
  }

  &__head {
    padding: 20px 0;
    border-bottom: 1px solid var(--light-grey-line);
  }

  &__body {
    flex: 1 1;
    padding: 10px 0;

    @media (max-width: $sm) {
      padding: 40px 0;
    }

    @media (max-width: $xs) {
      padding: 20px 0;
    }
  }

  &__foot {
    padding: 20px 0;
    border-top: 1px solid var(--light-grey-line);
  }

  &-wrap {
    display: flex;

    @media (max-width: $xs) {
      flex-direction: column;
    }

    &__main-menu,
    &__submenu {
      flex: 1 1;

      @media (max-width: $xs) {
        padding-right: 0;
        padding-left: 0;
        border-right: none;
      }
    }

    &__main-menu {
      border-right: 1px solid var(--light-grey-line);
      padding-right: 50px;

      @media (max-width: $xs) {
        margin-bottom: 20px;
      }

      .main-menu {
        ul {
          display: grid;
          grid-template-columns: 1fr;
          gap: 20px
        }
      }
    }

    &__submenu {
      padding-left: 50px;

      .submenu {
        ul {
          grid-template-columns: 1fr;
          gap: 20px;
        }
      }
    }
  }
}

.table-dropdown-active {
  .table-dropdown {
    @media (max-width: $lg ) {
      pointer-events: all;
      visibility: visible;
    }
  }
}