@import "header-head";
@import "header-nav";
@import "header-search";
@import "popup-search";

.header {
  position: sticky;
  top: 0;
  z-index: 9; 
  transition: background .2s ease-in-out;
  background: var(--current-body-color);

  @media (max-width: $sm) {
    background: var(--white);
  }

  @media (max-width: $lg ) {
    border-bottom: 1px solid var(--black);
  }

  &__container {
    position: relative;
  }
}

#nav-menu {
  position: absolute;
  top: 100%;
  display: none;
  padding: 40px;
  width: calc(100% - var(--container-side-padding) * 2);

  @media (max-width: $lg ) {
    display: none !important;
  }
}