.input{
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 17px 20px;
  font-family: $Inter;
  font-size: 14px;
  line-height: 140%;
  transition: all .3s ease;
  color: $grey;
  &:focus{
    border-color: $blue;
  }
  @media (max-width: $xxl) {
    padding: 13px 12px;
    font-size: 12px;
  }
}