.indicators{
  @media (max-width: $xxl) {
    padding-top: 100px;
    padding-bottom: 0;
  }
  @media (max-width: $sm){
    padding-top: 80px;
    padding-bottom: 0;
  }
  &__wrap{
    border: 10px solid #F6F6F6;
    position: relative;
    padding: 73px 63px 90px 63px;
    @media (max-width: $xxl) {
      padding: 60px 125px 60px 125px;
    }
    @media (max-width: $lg){
      padding: 60px;
    }
    @media (max-width: $sm){
      padding: 35px 20px 20px 20px;
    }
  }
  &__number{
    position: absolute;
    top: -43px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    @media (max-width: $xxl) {
      top: -30px;
    }
    .count--number-page{
      position: relative;
      background: $white;
      &:before{
        position: absolute;
        height: 100%;
        width: 40px;
        content: '';
        display: block;
        left: -45px;
        top: 43px;
        transform: rotate(-45deg);
        background: $white;
        @media (max-width: $xxl) {
          left: -8px;
          width: 20px;
        }
      }
      &:after{
        position: absolute;
        height: 100%;
        width: 40px;
        content: '';
        display: block;
        right: -30px;
        top: -57px;
        transform: rotate(-45deg);
        background: $white;
        @media (max-width: $xxl) {
          width: 20px;
          top: -20px;
        }
      }
    }
  }
  &__title{
    text-align: center;
  }
  &__content{
    margin: 40px auto 0 auto;
    text-align: center;
    max-width: 760px;
    line-height: 30px;
    @media (max-width: $xxl) {
      margin-top: 20px;
    }
    @media (max-width: $lg){
      line-height: 24px;
    }
  }
  &__list{
    margin-top: 60px;
    @media (max-width: $xxl) {
      margin-top: 40px;
    }
    @media (max-width: $sm){
      margin-top: 20px;
    }
  }
}