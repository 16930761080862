.steps{
  &__item{
    padding: 80px 0;
    border-bottom: 1px solid $grey_line;
    display: flex;
    align-items: center;
    &:last-child{
      @media (max-width: $lg) {
        border-bottom: none;
      }
    }
    @media (max-width: $xxl) {
      padding: 60px 0;
    }
    @media (max-width: $lg) {
      padding: 40px 0;
    }
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &-title{
      display: flex;
      flex:1;
      align-items: center;
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
      }
      h2{
        max-width: 200px;
        margin-left: 40px;
        @media (max-width: $xxl) {
          margin-left: 30px;
          max-width: 145px;
        }
        @media (max-width: $sm) {
          max-width: 100%;
          text-align: center;
          width: 100%;
          margin-left: 0;
          margin-bottom: 20px;
        }
      }
      .icon{
        @media (max-width: $sm) {
          margin-bottom: 20px;
        }
      }
    }
    &-content{
      flex: 2;
      font-size: 16px;
      line-height: 30px;
      @media (max-width: $xxl) {
        font-size: 14px;
        line-height: 24px;
      }
      @media (max-width: $lg) {
        flex: 1.5;
      }
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
        text-align: center;
      }
    }
  }
}