.icon-btn {
  display: flex;
  align-items: center;
  transition: color .2s ease-in-out;
  color: var(--black);

  &:hover {
    @media (min-width: $md) {
      text-decoration: none;
      color: var(--violet);
    }
  }

  &__icon {
    display: block;
    margin-left: 10px;
    pointer-events: none;
  }

  &-text {
    transform: scaleY(.5);
    overflow: hidden;

    @media (max-width: $xxl ) {
      font-size: 14px;
    }

    &__box {
      display: flex;
      flex-direction: column;
      transform: scaleY(2) translateY(25%);
      transition: transform .2s ease-in-out;
    }

    &__text {
      text-align: right;
      color: currentColor;
    }
  }

  &--active {
    .icon-btn {
      &-text {
        &__box {
          transform: scaleY(2) translateY(-25%);
        }
      }
    }
  }
}