@import "cc-homepage-table-movers";
@import "cc-company-data-table";
@import "cc-movers-table";

.entry-content {
  table {
    tr {
      border-bottom: 1px solid var(--light-grey-line);

      &:first-child {
        border-bottom: 1px solid var(--black);
      }
    }

    th, td {
      padding: 20px;
      &:first-child {
        max-width: 260px;
        div {
          max-width: 340px;
        }
      }
    }
  }
}