/*
 * ==================================================================================
 * reset
 * ==================================================================================
 */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img, .img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    
    &-fluid {
        width: 100%;
    }
}

.b-lazy {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
}

#site-main {
    max-width: 100vw;
    width: 100%;
    position: relative;
    flex: 1 1;
    overflow: hidden;
}

#site {
    height: 100vh;

    @media (max-width: $sm) {
        overflow-x: auto;
    }
}

input,
button {
    outline: none;
    border: none;
}

button {
    background: transparent;
    cursor: pointer;
    font-family: inherit;
}

ul {
    padding-left: 13px;
}

a {
    font-size: inherit;
    font-weight: inherit;
    color: var(--blue);
    line-height: inherit;
    text-decoration: none;

    &:hover {
        @media (min-width: $md) {
            text-decoration: underline;
        }
    }
}

table {
    border-collapse: collapse;
    width: 100%;
    border: none;
}

th {
    text-align: left;
}

svg, .svg {
    fill: currentColor;
    max-width: 100%;
    max-height: 100%;

    &-stroke {
        fill: transparent;
        stroke: currentColor;
    }
}

.sprite {
    display: none;
}

label, input, textarea, select {
    display: block;
    outline: none;
}

/*
 * ==================================================================================
 * body
 * ==================================================================================
 */

html {
    font-size: 16px;

    @media (max-width: $xxl ) {
        font-size: 14px;
    }
}

body {
    color: var(--black);
    min-width: var(--min-width-content);
    max-width: 100vw;
    font-weight: 300;
    font-family: $Merr;
    line-height: 1;
    background: var(--current-body-color);
    transition: background .2s ease-in-out;
    overflow: hidden;

    &.loaded {
        #preloader {
            opacity: 0;
            pointer-events: none;
        }
    }
}

#preloader {
    opacity: 1;
    pointer-events: all;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999999999;
    background: url(../img/preloader.gif) no-repeat center center / cover var(--white);
    transition: opacity .5s ease-in-out;
}

#wpadminbar {
    z-index: 99999999 !important;
}

/*
 * ==================================================================================
 * visually-hidden
 * ==================================================================================
 */

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

#wpadminbar {
    opacity: 1 !important;
}

@media screen and (max-width: 600px) {
    #wpadminbar {
        position: fixed;
    }
}