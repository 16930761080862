.analyst-profile{
  &__head{
    padding: 40px 0;
    display: grid;
    grid-template-areas:"analyst-current analyst-author analyst-noblelinx"
                        "analyst-stat analyst-author analyst-links";
    grid-template-rows: 140px auto;
    grid-template-columns: repeat(3,1fr);
    border-bottom: 1px solid $black;
    @media (max-width: $xxl) {
      padding: 30px 0;
      grid-template-rows: 85px auto;
    }
    @media (max-width: $lg) {
      grid-template-rows: 172px auto;
    }
    @media (max-width: $sm) {
      padding: 20px 0;
      grid-template-areas:"analyst-current analyst-current analyst-current"
                        "analyst-author analyst-author analyst-author"
                        "analyst-stat analyst-stat analyst-stat"
                        "analyst-links analyst-links analyst-links";
      grid-template-rows: auto;
    }
    &-current{
      grid-area: analyst-current;
      @media (max-width: $sm) {
      }
    }
    &-author{
      grid-area: analyst-author;
      @media (max-width: $sm) {
        margin-top: 15px;
      }
      &__image{
        display: block;
        margin: 0 auto;
        @media (max-width: $xxl) {
          width: 80px;
          height: 80px;
        }
      }
      &__title{
        font-weight: 300;
        line-height: 50px;
        text-align: center;
        margin-top: 20px;
        @media (max-width: $xxl) {
          margin-top: 15px;
          font-size: 26px;
          line-height: 32px;
        }
        @media (max-width: $sm) {
          margin-top: 10px;
        }
      }
      &__descr{
        font-weight: 300;
        line-height: 30px;
        text-align: center;
        @media (max-width: $xxl) {
          margin-top: 5px;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    &-noblelinx{
      grid-area: analyst-noblelinx;
      text-align: right;
      img{
        @media (max-width: $xxl) {
          width: 30px;
          height: 30px;
        }
        @media (max-width: $sm) {
          display: none;
        }
      }

    }
    &-stat{
      grid-area: analyst-stat;
      @media (max-width: $sm) {
        margin-top: 15px;
      }
      &__image{
        margin-top: 10px;
        margin-bottom: 10px;
        @media (max-width: $sm) {
          margin: 0 auto 10px auto;
          display: block;
        }
      }
      &__position{
        font-family: $Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: $xxl) {
          font-size: 12px;
        }
        @media (max-width: $sm) {
          text-align: center;
        }
      }
      &__raiting{
        font-family: $Inter;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        @media (max-width: $xxl) {
          font-size: 10px;
        }
        @media (max-width: $sm) {
          text-align: center;
        }
      }
    }
    &-social{
      grid-area: analyst-links;
      @media (max-width: $sm) {
        margin-top: 15px;
      }
    }

  }
  &__content{
    display: flex;
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
    &-text{
      width: calc(100% - 340px);
      padding-right: 20px;
      @media (max-width: $lg) {
        width: 100%;
        padding-right: 0;
      }
      @media (max-width: $xxl) {
        padding-right: 15px;
        width: calc(100% - 235px);
      }
      @media (max-width: $lg) {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}