.events{
  &__head{
    display: flex;
    font-family: $Inter;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
    &-select{
      margin-left: 10px;
      -webkit-appearance: none;
    }
  }
  &__more{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
