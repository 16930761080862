.cc-company-data-table {
  --cc-columns-ratio: 3.28fr 1.76fr .98fr 1.68fr 1.22fr 1.04fr 1fr;

  @media (max-width: $xxl ) {
    --cc-columns-ratio: 2.28fr 1.6fr .97fr 1.53fr 1.13fr .83fr 1fr;
  }

  @media (max-width: $sm) {
    --cc-columns-ratio: auto 1fr;
  }

  &--research-report { 
    border: 1px solid var(--light-grey-line);

    @media (max-width: $sm) {
      border: none;
    }

    .cc-company-data-table-card__header,
    .cc-company-data-table-card__footer {
      padding-left: 0;
      padding-right: 0;
      margin-left: 20px;
      margin-right: 20px;

      @media (max-width: $xxl ) {
        margin-right: 15px;
        margin-left: 15px;
      }

      @media (max-width: $sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .cc-company-data-table {
      &__header {
        border-bottom: 1px dashed var(--light-grey-line);
        padding-left: 0;
        padding-right: 0;
        margin-left: 20px;
        margin-right: 20px;

        @media (max-width: $xxl ) {
          margin-right: 15px;
          margin-left: 15px;
        }

        @media (max-width: $sm) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &__body {
        padding-bottom: 0;
        margin-bottom: -1px;
      }
    }
  }

  &__header {
    padding: var(--column-gap);
    border-bottom: 1px solid var(--black);
    display: grid;
    grid-template-columns: var(--cc-columns-ratio);

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__body {
    padding-bottom: 20px;

    @media (max-width: $xxl ) {
      padding-bottom: 15px;
    }

    @media (max-width: $sm) {
      padding-bottom: 20px;
    }
  }
}