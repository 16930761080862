.offerings{
  @media (max-width: $lg) {
    padding-bottom: 0;
  }
  @media (max-width: $sm) {
    padding-top: 20px;
    padding-bottom: 0;
  }
  &__head{
    display: flex;
    @media (max-width: $sm) {
      display: none;
    }
    &-title{
      margin-left: 20px;
    }
  }
}