.analyst-list{
  padding-left: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  @media (max-width: $sm) {
    grid-template-columns: 1fr;
  }
  &__item{
    position: relative;
    border-right: 1px solid $grey_light;
    border-bottom: 1px solid $grey_light;
    @media (max-width: $sm) {
      border-right: none;
    }
    &:nth-child(even){
      border-right: none;
      &:after{
        right: auto;
        left: 0;
      }
    }
    &:nth-child(-4n + 4){
      &:before{
        position: absolute;
        width: 1px;
        height: 15px;
        right: auto;
        left: -1px;
        bottom: auto;
        top: 0;
        display: block;
        content: '';
        background: $white;
      }
    }
    &:first-child{
      @media (max-width: $sm) {
        border-top: 1px solid $grey_light;
      }
    }
    &:after{
      position: absolute;
      width: 15px;
      height: 1px;
      right: 0;
      bottom: -1px;
      display: block;
      content: '';
      background: $white;
      @media (max-width: $sm) {
        display: none;
      }
    }
    &:before{
      position: absolute;
      width: 1px;
      height: 15px;
      right: -1px;
      bottom: 0;
      display: block;
      content: '';
      background: $white;
      @media (max-width: $sm) {
        display: none;
      }
    }
    &:hover{
      .analyst-list__link{
        text-decoration: none;
        &-images{
          padding-right: 15%;
          @media (max-width: $lg) {
            padding-right: 0;
          }
          &__icon{
            visibility: visible;
            opacity: 1;
            @media (max-width: $lg) {
              display: none;
            }
          }
        }
      }
    }
  }
  &__link{
    --current-color: var(--grey);

    display: flex;
    align-items: center;
    padding: 20px 60px 40px 60px;
    color: $black;

    &--green {--current-color: var(--green)}
    &--orange {--current-color: var(--orange)}
    &--blue {--current-color: var(--blue)}
    &--turquoise {--current-color: var(--turquoise)}

    @media (max-width: $xxl) {
      padding: 20px 35px;
    }
    @media (max-width: $lg) {
      padding: 20px 25px;
    }
    @media (max-width: $sm) {
      padding: 20px 0;
    }
    &-info{
      flex: 1;
      min-width: 50%;
      &__title{
        font-family: $Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 125%;
        margin-bottom: 44px;
        min-height: 55px;
        @media (max-width: $xxl) {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 54px;
          min-height: 46px;
        }
        @media (max-width: $sm) {
          margin-bottom: 10px;
        }
      }
      &__name{
        font-family: $Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &-images{
      flex: 1;
      display: flex;
      justify-content: end;
      align-items:center;
      padding-right: 0;
      transition: all .3s ease;
      &__author{
        @media (max-width: $xxl) {
          width: 60px;
          height: 60px;
        }
      }
      &__sector{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: $grey_light;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        img{
          width: 30px;
          height: 30px;
        }
        @media (max-width: $xxl) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: $sm) {
          margin-left: 15px;
        }
      }
      &__icon{
        height: 100%;
        background: var(--current-color);
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease;
        opacity: 0;
        visibility: hidden;
        svg{
          fill: $white;
        }
      }
    }
  }
}