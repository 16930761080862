.cc-company-data-table-card {
  // --cc-columns-ratio

  &:first-child {
    .cc-company-data-table-card__header {
      @media (max-width: $sm) {
        padding-top: 0;
      }
    }
  }

  border-bottom: 1px solid var(--light-grey-line);

 &__box {
   position: relative;
   z-index: 2;
   pointer-events: none;
 } 

 &__like-btn {
   pointer-events: all;
 }

 &__header {
  display: grid;
  grid-template-columns: var(--cc-columns-ratio);
  align-items: center;
  padding: 30px var(--column-gap);
  border-bottom: 1px dashed var(--light-grey-line);

  @media (max-width: $xxl ) {
    padding: 20px var(--column-gap);
  }

  @media (max-width: $sm) {
    padding: 20px 0;
    column-gap: var(--column-gap);
    row-gap: 5px;
  }
 }

 &__title {
  display: grid;
  grid-template-columns: 36px auto;
  gap: 5px;
  align-items: center;
  color: var(--blue);
  text-decoration: underline;

  .text-small {
    @media (max-width: $sm) {
      font-size: 16px;
    }
  }

  @media (max-width: $sm) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    margin-bottom: 10px;
    grid-column: 1/3;         

    .cc-custom-hover__img {
      display: none;
    }
  }
 }

  &__footer {
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-template-areas: "like stars rating btns";
    align-items: center;
    padding: var(--column-gap);
    gap: var(--column-gap);

    @media (max-width: $sm) {
      grid-template-columns: auto 1fr;
      grid-template-areas: 
        "stars stars" 
        "rating rating"
        "like btns"
      ;
      column-gap: var(--column-gap);
      row-gap: 5px;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: calc($less - 1px)) {
      grid-template-columns: 1fr;
      grid-template-areas: 
        "stars" 
        "rating"
        "like"
        "btns"
      ;
    }
  }

  &__like-wrap  {
    grid-area: like;
  }

  &__stars-wrap {
    grid-area: stars;
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    align-items: center;

    @media (max-width: $lg ) {
      grid-template-columns: 80px auto;
    }

    @media (max-width: $sm) {
      grid-template-columns: auto 1fr;
    }
  }

  &__stars {
    display: grid;
    grid-template-columns: repeat(5, 16px);
    gap: 5px;
  }

  &__stars-title {
    @media (max-width: $sm) {
      white-space: nowrap;
    }
  }

  &__star {
    svg {
      fill: transparent;
      stroke: var(--grey);
    }

    &--active {
      svg {
        stroke: var(--green);
        fill: var(--green);
      }
    }
  }

  &__rating-wrap {
    grid-area: rating;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    align-items: center;

    @media (max-width: $lg ) {
      grid-template-columns: 1fr;
      gap: 0px;
    }

    @media (max-width: $sm) {
      grid-template-columns: auto 1fr;
      gap: 5px;
    }
  }

  &__btns-wrap {
    grid-area: btns;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -15px;

    a {
      margin-right: 15px;
      pointer-events: all;
    } 

    @media (max-width: calc($less - 1px)) {
      justify-content: flex-start;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: -5px;
      margin-right: 0;

      a {
        margin-right: 0;
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }

  &:not(.cc-company-data-table-card--nolink).cc-custom-hover--active {
    @media (min-width: $md) {
      background: var(--light-grey-bgr);

      .cc-company-data-table-card {
        &__title {
          text-decoration: none;
        }
      }
    }
  }

  &.cc-company-data-table-card--nolink.cc-custom-hover--active {
    @media (min-width: $md) {
      .cc-custom-hover__img {
        &::after { 
          border-width: 0px;
        }
      }
    }
  }

  &__like-wrap,
  &__btns-wrap {
    @media (max-width: $sm) {
      margin-top: 10px;
    }
  }
}

.cc-company-data-table-card--nolink {
  .cc-company-data-table-card__title {
    @media (max-width: $sm) {
      grid-template-columns: 36px auto;
      gap: 10px;

      .cc-custom-hover__img { 
        display: block;
      }
    }
  }
}