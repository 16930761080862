.contacts{
  list-style: none;
  padding-left: 0;
  &__item{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__link{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    text-decoration: underline;
    color: $blue;
  }
  &__icon{
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $blue;
    margin-right: 10px;
    border-radius: 50%;
    svg{
      fill: $white;
    }
  }
}