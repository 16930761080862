h1, .h1 {
  font-size: 40px;
  line-height: 1.25em;
  font-weight: 300;
  width: 100%;
  
  @media (max-width: $xxl) {
    font-size: 26px;
    line-height: 33px;
  }

  &--arrow-right{
    display: flex;
    align-items: center;
    font-weight: 300;
    &:before{
      display: block;
      content: '';
      width: 20px;
      height: 40px;
      background: url("/img/chevron-right-title.svg") no-repeat;
      background-size: contain;
      margin-right: 20px;
    }
  }
}

h2, .h2 {
  font-size: 30px;
  line-height: 1.26em;
  font-weight: 300;
  width: 100%;

  
  @media (max-width: $xxl){
    font-size: 22px;
    line-height: 28px;
  }
  &--arrow-right{
    display: flex;
    align-items: center;
    font-weight: 300;
    &:before{
      display: block;
      content: '';
      width: 18px;
      height: 36px;
      background: url("/img/chevron-right-title.svg") no-repeat;
      background-size: contain;
      margin-right: 15px;
    }
  }
  &--page{
    font-weight: 300;
    font-size: 60px;
    line-height: 75px;
    @media (max-width: $xxl){
      font-size: 40px;
      line-height: 50px;
    }
    @media (max-width: $sm){
      font-size: 26px;
      line-height: 33px;
    }
  }
}

h3, .h3 {
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 300;
  width: 100%;

  &-inter {
    font-size: 22px;
    font-family: $Inter;
    font-weight: 500;
    width: 100%;

    @media (max-width: $xxl ) {
      font-size: 18px;
    }
  }

  &--arrow-right{
    display: flex;
    align-items: center;
    &:before{
      display: block;
      content: '';
      width: 15px;
      height: 30px;
      background: url("/img/chevron-right-title.svg") no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }
}
h4,.h4{
  font-family: $Inter;
  font-weight: 500;
  font-size: 18px;
  line-height: 125%;
  margin-bottom: 20px;
  margin-top: 40px;
  width: 100%;
  @media (max-width: $xxl) {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  @media (max-width: $sm) {
    margin-top: 20px;
  }

  &--arrow-right{
    display: flex;
    align-items: center;
    &:before{
      display: block;
      content: '';
      width: 12px;
      height: 25px;
      background: url("/img/chevron-right-title.svg") no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }
}
h6,.h6{
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
}

h5, .h5 {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.25em;
  width: 100%;

  @media (max-width: $xxl ) {
    font-size: 14px;
    line-height: 18px;
  }
}


.text, p {
  line-height: 1.87em;
  font-size: 16px;

  @media (max-width: $xxl ) {
    font-size: 16px;
    line-height: 1.42em;
  }
}

p {
  margin: 20px 0;

  @media (max-width: $xxl ) {
    margin: 15px 0;
  }
}

.text {
  margin: 0;
  @media (max-width: $xxl ) {margin: 0;}

  @include option('text-align', $array_sizes, ('left', 'center', 'right'));

  &-less {
    font-size: 12px;
    line-height: 1.67em;

    @media (max-width: $xxl) {
      font-size: 10px;
    }
  }

  &-small {
    font-size: 14px;
    line-height: 1.42em;

    @media (max-width: $xxl ) {
      font-size: 12px;
      line-height: 1.66em;
    }
  }

  &-big {
    font-size: 18px;
    line-height: 1.25em;

    @media (max-width: $xxl ) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &-extra-big {
    font-size: 22px;
    line-height: 27px;

    @media (max-width: $xxl ) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-inter {
    font-family: $Inter;
  }

  &-light{
    font-weight: 300;
  }

  &-normal {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }
  
  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-nowrap {
    white-space: nowrap;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-transform-none {
    text-transform: none;
  }
  
  &-white {
    color: var(--white);
  }

  &-grey {
    color: var(--grey);

    a, &a {
      text-decoration: underline;
      color: var(--grey);

      &:hover {
        @media (min-width: $md) {
          text-decoration: none;
        }
      }
    }
  }

  &-green {
    color: var(--green);
  }

  &-red {
    color: var(--red);
  }

  &-blue {
    color: var(--blue);
  }

  &-turquoise {
    color: $Turquoise;
  }
 
  &-orange {
    color: var(--orange);
  }

  &-violet {
    color: var(--violet);
    &--line{
      color: $grey_line;
    }
  }

  &-yellow {
    color: var(--yellow);
  }

  &-merr{
    font-family: $Merr;
  }

  &-line {
    span {
      background-color: rgba(46, 46, 130, .2);
      box-shadow: 0px 0 0 rgba(46, 46, 130, .2), 4px 0 0 rgba(46, 46, 130, .2);
    }
  }
}

.entry-content {
  p {
    margin: 0;
    margin-bottom: 40px;
    width: 100%;

    @media (max-width: $xxl ) {
      margin: 0;
      margin-bottom: 30px;
    }

    @media (max-width: $sm) {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  a {
    max-width: 100%;
  }

  h2 {
    margin: 40px 0;

    @media (max-width: $xxl ) {
      margin: 35px 0;
    }

    @media (max-width: $sm) {
      margin: 45px 0 25px;
    }
  }

  h3 {
    margin: 40px 0 30px;

    @media (max-width: $xxl ) {
      margin: 35px 0 20px;
    }
  }
}