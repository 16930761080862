.ham {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 20px;
  padding: 5px 0;

  span {
    display: block;
    height: 2px;
    width: 100%;
    background: currentColor;
    transition: transform-origin .2s ease-in-out, opacity .2s ease-in-out, transform .2s ease-in-out;
  }

  &.active {
    span {
      transform-origin: left;
      transition: opacity .2s ease-in-out, transform .1s ease-in-out .1s;

      &:nth-child(1){
        transform: translateY(-50%) rotate(45deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        transform: translateY(50%) rotate(-45deg);
      }
    }
  }
}

a.ham {
  color: var(--black);
}