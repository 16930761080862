.icon{
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $grey_light;
  @media (max-width: $xxl) {
    width: 60px;
    height: 60px;
  }
  &__image{
    @media (max-width: $xxl) {
      width: 30px;
      height: 30px;
    }
  }
}