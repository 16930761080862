/*
 * ==================================================================================
 * includes
 * ==================================================================================
 */

@import '../../node_modules/vanillajs-datepicker/dist/css/datepicker.min.css';
@import '../../node_modules/vanillajs-datepicker/dist/css/datepicker-bulma.min.css';
@import '~swiper/css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~nouislider/dist/nouislider.css';
@import "~animate.css/animate.min.css";

@import "variables";
@import "mixins";

@import "fonts";
@import "settings";

@import "Global/index";
@import "Pavel/index";
@import "Andrey/index";
@import "Global/box";