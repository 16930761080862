.count{
  &-pages{
    position: absolute;
    display: inline-block;
    width: 100%;
    text-align: center;
    bottom: 20px;
    left: 0;
    @media (max-width: $xxl){
      bottom: 30px;
    }
    @media (max-width: $sm) {
      bottom: 20px;
    }
    span{
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $white;
      padding: 15px 21px;
      background: rgba(29,29,29,.5);
      border-radius: 60px;
      display: inline-block;
      @media (max-width: $xxl){
        padding: 5px 14px;
        font-size: 12px;
      }
    }
  }
  &--number-page{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: $blue;
    border: 2px solid $blue;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    @media (max-width: $xxl) {
      width: 50px;
      height: 50px;
      font-size: 14px;
      line-height: 18px;
    }
    span{
      transform: rotate(-45deg);
    }
  }
}