.daterange {
  display: flex;
  align-items: center;
  padding: 1px;

  &__wrap {
    position: relative;
    margin: -1px;
  }

  &__field {
    position: relative;
    cursor: pointer;
    &:focus {
      z-index: 1;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    fill: var(--blue);
    z-index: 2;
  }

  &__start {
    border-radius: 8px 0 0 8px;
  }
  
  &__end {
    border-radius: 0 8px 8px 0;
  }

  .datepicker-dropdown {
    transform: translateX(-50%);
    left: 50% !important;
    padding-top: 10px;
    font-family: $Inter;

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background: var(--white);
      border: 1px solid var(--light-grey-line);
      height: 24px;
      width: 24px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 11px;
      width: 24px;
      height: 5px;
      background: var(--white);
      left: 50%;
      transform: translateX(-50%);
    }

    &.datepicker-orient-top {
      &::before, 
      &::after {
        display: none;
      }
    }

    .datepicker-picker {
      background: var(--white);
      border: 1px solid var(--light-grey-line);
      box-shadow: 0px 5px 10px rgba(29, 29, 29, 0.1);
      border-radius: 8px;
    }

    .datepicker-header {
      padding-bottom: 18px;

      .datepicker-controls {
        border-bottom: 1px solid var(--light-grey-line);
        align-items: center;

        .button {
          color: var(--black);

          &.view-switch {
            font-weight: 500;
            font-size: 14px;
          }

          svg {
            fill: var(--blue);
          }
        }
      }
    }

    .datepicker-view {
      --datepicker-button-size: 24px;

      padding: 0 20px;
      padding-bottom: 20px;
      

      .days-of-week {
        margin-bottom: 10px;
      }

      .dow {
        font-weight: 400;
        font-size: 10px;
        color: var(--black);
        height: auto;
        line-height: 2em;
        flex-basis: var(--datepicker-button-size);
      }

      .days {
        .datepicker-grid {
          display: grid;
          grid-template-columns: repeat(7, var(--datepicker-button-size));
          gap: 5px;
          width: 100%;

          .datepicker-cell {
            height: var(--datepicker-button-size);
            width: var(--datepicker-button-size);
            flex-basis: var(--datepicker-button-size);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            font-weight: 400;
            font-size: 12px;

            &.selected {
              background: var(--violet);
              border-radius: 3px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}