.video{
  position: relative;
  &__preview{
    position: relative;
    display: block;
    transition: all .3s ease;
    &:before{
      position: absolute;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      display: block;
      top: 0;
      left: 0;
      content: '';
      border: 10px solid #00CCFF;
      transition: all .3s ease;
      opacity: 0;
    }
    &:hover{
      &:before{
        opacity: 1;
      }
      .video{
        &__play{
          background: $Turquoise;
        }
      }
    }
    img{
      object-fit: cover;
      width: 100%;
      height: 170px;
      transition: all .3s ease;
      @media (max-width: $lg) {
        height: auto;
      }
    }
  }
  &__play{
    position: absolute;
    left: calc(50% - 30px);
    padding: 20px;
    top: calc(50% - 30px);
    background: rgba(29, 29, 29, .5);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: all .3s ease;
    svg{
      fill: $white;
    }
  }
  &__content{
    margin-top: 15px;
    &-title{
      margin: 0 0 10px 0;
      font-family: $Merr;
      text-decoration: none;
      color: $black;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__category{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  &__info{
    display: flex;
    align-items: center;
    .favorites{
      height: 16px;
      margin-right: 20px;
      svg{
        fill: #848484;
      }
    }
    .date{
      font-family: $Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color:$grey;
      margin-right: 20px;
    }
  }
}