.investment-risk{
  @media (max-width: $sm) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  &__head{
    display: flex;
    h2{
      margin-left: 20px;
      @media (max-width: $sm) {
        text-align: left;
      }
    }
  }
  &__content{
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 40px;
    font-size: 16px;
    line-height: 30px;
    @media (max-width: $xxl) {
      margin-top: 60px;
      gap: 30px;
      font-size: 14px;
      line-height: 24px;
    }
    @media (max-width: $lg){
      gap: 20px;
    }
    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      gap: 0;
      margin-top: 40px;
    }
    div{
      @media (max-width: $sm) {
        margin-bottom: 40px;
      }
      &:last-child{
        @media (max-width: $sm) {
          margin-bottom: 0;
        }
      }
      p{
        @media (max-width: $xxl){
          font-size: 14px;
          line-height: 24px;
        }
        @media (max-width: $lg){

        }
        &:first-child{
          margin-top: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}