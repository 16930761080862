.link{
  &-style{
    &-2{
      font-family: $Merr;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;
      color: $blue;
      transition: all .3s ease;
      &:hover{
        color: $orange;
      }
    }
    &-3{
      font-family: $Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $grey;
      transition: all .3s ease;
      border-bottom: 1px solid transparent;
      @media (max-width: $xxl) {
        font-size: 12px;
        line-height: 20px;
      }
      &:hover{
        text-decoration: none;
        border-bottom: 1px solid $grey;
      }
    }
  }
  &--back{
    font-family: $Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    display: flex;
    align-items: center;
    @media (max-width: $xxl) {
      font-size: 12px;
    }
    svg{
      fill: $blue;
      margin-right: 5px;
    }
  }
}