.search-card {
  --cc-search-card-side-padding: 20px;

  position: relative;

  @media (max-width: $xxl ) {
    --cc-search-card-side-padding: 15px;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    &:hover + .search-card__box {
      .search-card {
        @media (min-width: $md) {
          &__title {
            text-decoration: none;
          }

          &__img-box {
            &::after {
              border-width: 5px;
            }
          }
        }
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--cc-search-card-side-padding);
  }

  &__content {
    flex: 1 1;
  }

  &__img {
    transition: transform .3s;

    &-box {
      position: relative;
      
      &--video,
      &--news {
        overflow: hidden;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border: 0px solid transparent;
        transition: all .2s ease-in-out;
        box-sizing: border-box;
      }

      &--research,
      &--company {
        padding: 5px;
      } 

      &--video {
        &::after {
          border-color: var(--turquoise);
        }
      }            
      
      &--research {
        &::after {
          border-color: var(--violet);
        }
      }            
      
      &--news {
        &::after {
          border-color: var(--orange);
        }
      }            
      
      &--company {
        &::after {
          border-color: var(--blue);
        }
      }        
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__icon {
    --cc-icon-size: 20px;

    margin-right: 5px;
  }

  &__title {
    color: var(--blue);
    text-decoration: underline;
    margin-bottom: 10px;
  }

  &__play {
    --cc-play-size: 30px;
  }
}