.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;

  &--reverse {
    .checkbox {
      &__box {
        svg {
          color: var(--white);
        }
      }

      &__field {
        &:checked {
          & + .checkbox__box {
            background: var(--blue);
    
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__field {

    &:checked {
      & + .checkbox__box {
        background: var(--white);
        border: 1px solid var(--blue);

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border: 1px solid var(--light-grey-bgr);
    border-radius: 3px;
    background: var(--light-grey-bgr);
    transition: background .2s ease-in-out;
    margin-right: 10px;

    svg {
      opacity: 0;
      color: var(--blue);
      transition: opacity .2s ease-in-out;
    }
  }

  &__text {
    flex: 1 1;
  }

  &-accordion {

    & > .checkbox .checkbox__box {
      margin-right: 0;
    }

    &__heading {
      position: relative;
      display: block;
      padding: 0 10px;
      height: 12px;
      width: 12px;
      box-sizing: content-box;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        width: 2px;
        background: var(--blue);
        transform: translateY(-50%);
        transition: transform .2s ease-in-out;
      }

      &::after {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &__box {
      padding-left: 34px;
      display: grid;
      grid-template-columns: 1fr;
      gap: 10px;
      padding-top: 10px;
    }
  }

  .accordion__body {
    width: 100%;
  }
}

.accordion--active {
  & > .checkbox .checkbox-accordion__heading {
    &::before {
      transform: translateY(-50%) rotate(90deg);
    }

    &::after {
      transform: translateY(-50%) rotate(270deg);
    }
  }

  & > .checkbox .checkbox__text {
    color: var(--blue);
  }
}