.social-list {
  list-style: none;
  padding: 0;

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
  }

  &__link {
    display: block;
    transition: color .2s ease-in-out;
    color: var(--black);

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
        text-decoration: none;
      }
    }

    &--youtube:hover {
      @media (min-width: $md) {
        color: #FE0100;
      }
    }
    
    &--instagram:hover {
      @media (min-width: $md) {
        color: #EB4966;
      }
    }
    &--facebook:hover {
      @media (min-width: $md) {
        color: #1577F1;
      }
    }
    &--linkedin:hover {
      @media (min-width: $md) {
        color: #0966C3;
      }
    }
    &--twitter:hover {
      @media (min-width: $md) {
        color: #1D9BF0;
      }
    }
  }
}