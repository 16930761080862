.contact-us-list {
  list-style: none;
  padding: 0;
  display: inline-grid;
  grid-template-columns: 1fr;
  gap: 10px;

  &__item {
    --current-color: var(--blue);

    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 10px;
    align-items: center;

    &--orange {--current-color: var(--orange)}
    &--green {--current-color: var(--green)}
    &--violet {--current-color: var(--violet)}

    @media (max-width: $xxl ) {
      grid-template-columns: 20px 1fr;
    }
  }
  
  &__icon {
    color: var(--current-color);
  }

  &__link {
    color: var(--black);
  }

  a.contact-us-list__link {
    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
      }
    }
  }
}