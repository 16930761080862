.cc-hero-slider-card {
  margin-right: 20px;

  &__title {
    color: var(--blue);
  }

  &__box {
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
    padding-right: 20px;
    border-right: 1px solid var(--light-grey-line);
  }
}