.community-list{
  display: flex;
  margin-top: 60px;
  @media (max-width: $xxl) {
    margin-top: 40px;
  }
  @media (max-width: $lg){
    margin-top: 20px;
  }
  @media (max-width: $sm) {
    flex-wrap: wrap;
    margin-top: 0;
  }
  &__item{
    flex: 1;
    border-right: 1px solid $grey_line;
    @media (max-width: $sm) {
      flex: auto;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid $grey_line;
      padding: 20px 0;
    }
    &:last-child{
      border-right: none;
      @media (max-width: $sm) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
    &-image{
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
    &-content{
      max-width: 320px;
      margin: 20px auto 0 auto;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: $xxl) {
        max-width: 255px;
        font-size: 12px;
      }
      @media (max-width: $lg){
        max-width: 180px;
      }
      @media (max-width: $sm) {
        max-width: 100%;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}