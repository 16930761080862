.related-companies{
  h4{
    margin: 0 0 20px 0;
    padding-top: 40px;
    border-top: 1px solid $black;
    @media (max-width: $xxl) {
      padding-top: 30px;
      margin: 0 0 15px 0;
    }
    @media (max-width: $sm) {
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }
}