.organizations-list{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-column-gap: 40px;
  @media (max-width: $xxl) {
    grid-column-gap: 30px;
  }
  @media (max-width: $lg) {
    grid-column-gap: 20px;
  }
  @media (max-width: $sm) {
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 16px;
  }
  &__item{
    padding: 65px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: $xxl) {
      padding: 49px 30px;
    }
    @media (max-width: $lg) {
      padding: 33px 20px;
    }
    @media (max-width: $sm) {
      padding: 44px 20px;
    }
    img{
      @media (max-width: $xxl) {
        max-width: 90px;
      }
      @media (max-width: $lg) {
        max-width: 60px;
      }
      @media (max-width: $sm) {
        max-width: 112px;
      }
    }
    &:hover{
      .organizations-list{
        &__item{
          &-border{
            opacity: 1;
          }
        }
      }
    }
    &:before{
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      height: 1px;
      background: $grey_line;
    }
    &:after{
      position: absolute;
      width: 1px;
      top: 20px;
      right: -20px;
      display: block;
      content: '';
      height: calc(100% - 40px);
      background: $grey_line;
      @media (max-width: $xxl) {
        height: calc(100% - 30px);
        top: 15px;
        right: -15px;
      }
      @media (max-width: $lg) {
        height: calc(100% - 20px);
        top: 10px;
        right: -10px;
      }
      @media (max-width: $sm) {
        top: 8px;
        right: -8px;
        height: calc(100% - 16px);
      }
    }
    &:nth-child(6n){
      &:after{
        display: none;
      }
      &:before{

      }
    }
    &:nth-child(even){
      &:after{
        display: none;
      }
    }
    &-border{
      position: absolute;
      display: block;
      content: '';
      height: 100%;
      width: calc(100% + 41px);
      top: 0;
      left: -20px;
      border: 10px solid $blue_2;
      transition: all .3s ease;
      z-index: 2;
      opacity: 0;
      @media (max-width: $xxl){
        left: -15px;
        width: calc(100% + 31px);
      }
      @media (max-width: $lg) {
        left: -10px;
        width: calc(100% + 21px);
      }
      @media (max-width: $sm) {
        left: -8px;
        width: calc(100% + 17px);
      }
    }
  }
}