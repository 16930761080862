.cc-homepage-video-card {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    &:hover {
      @media (min-width: $md) {
        & + .cc-homepage-video-card__box {
          .cc-homepage-video-card {
            &__title {
              color: var(--turquoise);
              text-decoration: underline;
            }
  
            &__img-box::after {
              border-width: 10px;
            }
          }
        }
      }
    }
  }

  &__img {
    width: 100%;

    @media (max-width: $sm) {
      max-height: 186px;
      object-fit: cover;
    }
    
    @media (max-width: $less) {
      max-height: 132px;
      object-fit: cover;
    }
  }

  &__img-box {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0px solid var(--turquoise);
      transition: all .2s ease-in-out;
      box-sizing: border-box;
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 10px;
    transition: all .2s ease-in-out;
  }

  &__desc {
    flex: 1 1;
  }

  &__box {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
}