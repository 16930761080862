.field, .textarea {
  border: 1px solid var(--blue);
  background: var(--white);
  resize: none;

  &--error {
    border-color: var(--red) !important;
  }

  &-dark {
    border: 1px solid var(--blue);
    background: var(--white);
  }

  &, &-dark {
    line-height: 38px;
    width: 100%;
    color: var(--black);
    border-radius: 8px;
    font-size: 14px;
    padding: 0 15px;
    font-family: $Inter;
    font-weight: 500;

    &.select {
      height: 40px;
    }

    @media (max-width: $xxl ) {
      line-height: 34px;
      font-size: 12px;

      &.select {
        height: 36px;
      }
    }

    &--big {
      line-height: 58px; 

      @media (max-width: $xxl ) {
        line-height: 38px;
      }

      &.select {
        height: 60px;

        @media (max-width: $xxl ) {
          height: 40px;
        }
      }
    }

    &:focus {
      border-width: 2px;
      line-height: 36px;
      padding: 0 14px;

      @media (max-width: $xxl ) {
        line-height: 32px;
  
        &.select {
          height: 34px;
        }
      }
  
      &.field--big, &.field-dark--big {
        line-height: 56px; 
  
        @media (max-width: $xxl ) {
          line-height: 36px;
        }
  
        &.select {
          height: 58px;
  
          @media (max-width: $xxl ) {
            height: 38px;
          }
        }
      }
    }
  }

  &-password {
    position: relative;

    &__link {
      color: var(--grey);
      transition: color .2s ease-in-out;
      display: block;
      position: absolute;
      top: 52%;
      right: 20px;
      transform: translateY(-50%);

      @media (max-width: $xxl ) {
        right: 10px;
        height: 16px;
        width: 16px;
      }

      &:hover {
        @media (min-width: $md) {
          color: var(--violet);
        }
      }

      &--active {
        color: var(--blue);
      }
    }
  }
}

::placeholder {
  color: var(--grey);
}

select, .select {
  cursor: pointer;
  color: var(--grey);
  padding-left: 32px;

  &-active {
    color: var(--black);
  }

  &-box {
    position: relative;
    color: var(--blue);
    transition: color .2s ease-in-out;

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
      }
    }

    &::after {
      content: '';
      background: var(--white);
      position: absolute;
      top: 47%;
      right: 5px;
      height: 15px;
      width: 10px;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &__icon {
      position: absolute;
      top: 43%;
      right: 15px;
      transform: translateY(-50%) rotate(90deg);
      pointer-events: none;
      width: 20px;
      height: 20px;
    }
  }

  option {
    color: var(--black);
  }
}

@import "checkbox";
@import "range";
@import "daterange";
@import "user-image";
@import "select-list";
@import "select-tabs";