.cc-video-thumb-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    flex: 1 1;
    display: grid;
    grid-template-areas:
      "img" 
      "title"
      "desc"
      "footer"
    ; 
    gap: 10px;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__play-btn {
    --cc-play-size: 60px;

    @media (max-width: $xxl ) {
      --cc-play-size: 40px;
    }
  }

  &__img {
    grid-area: img;

    .cc-custom-hover__img {
      width: 100%;
    }

    img {
      width: 100%; 
    }
  }

  &__title {
    grid-area: title;
    margin: 0 !important;
  }

  &__desc {
    grid-area: desc;
  }

  &__footer {
    grid-area: footer;
  }
}