/*
 * ==================================================================================
 * SCSS
 * ==================================================================================
 */

$Merr: "Merriweather", serif;
$Inter: "Inter", sans-serif;

$grey: #848484;
$grey_light:#F6F6F6;
$grey_line: #E8E8E8;
$black: #1D1D1D;
$white: #fff;
$green: #4BB14B;
$blue: #2E2E82;
$blue_2: #4F4FAD;
$orange: #FF6600;
$Turquoise: #00CCFF;
$purple: #A84CF0;
$yellow: #FFC107;
$red: #E73D26;


/* 
 * ==================================================================================
 * Windows size
 * ==================================================================================
 */

$extra-less: 340px;
$less: 360px;
$xx: 480px;
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1512px; 

$array_sizes: (
  ( 'none', 'none' ), 
  ( $less, 'less' ), 
  ( $xx, 'xx' ), 
  ( $xs, 'xs' ), 
  ( $sm, 'sm' ), 
  ( $md, 'md' ), 
  ( $lg, 'lg' ), 
  ( $xl, 'xl' ),
  ( $xxl, 'xxl' ),
);

/* 
 * ==================================================================================
 * Root
 * ==================================================================================
 */

:root {
  --black: #1D1D1D;
  --white: #fff;

  --grey: #848484;
  --light-grey-bgr: #F6F6F6;
  --light-grey-line: #E8E8E8;

  --blue: #2E2E82;
  --yellow: #FFC107;
  --green: #4BB14B;
  --orange: #FF6600;
  --turquoise: #00CCFF;
  --red: #E73D26;
  --violet: #4F4FAD;

  --current-body-color: var(--white);

  --column-gap: 20px;

  --admin-bar-height: 0px;
  
  --container-side-padding: 20px;
  --container-width: 1400px;

  --min-width-content: 320px;

  @media (max-width: $xxl) {
    --container-width: 1050px;
    --column-gap: 15px;
  }

  @media (max-width: $lg ) {
    --container-width: 700px;
  }
}

.admin-bar {
  --admin-bar-height: 32px;
  
  @media (max-width: 782px) {
    --admin-bar-height: 46px;
  }
}