.submenu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  a {
    font-size: 14px;
    line-height: 1.42em;
    position: relative;
    color: var(--black);
    
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background: currentColor;
      transition: width .2s ease-in;
    }

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
        text-decoration: none;
  
        &::before {
          width: 100%;
        }
      }
    }
  }
}