#site {
  & > .ps__rail-y {
    right: 2px !important;
    z-index: 10;
  }
}

.table-dropdown-active {
  #site {
    & > .ps__rail-y {
      z-index: 1;
    }
  }
}

.ps__rail-y {
  width: 2px;
  background: var(--light-grey-bgr);
  opacity: 1 !important;
  border-radius: 5px;
  overflow: hidden;
  transition: width .2s ease-in-out;

  &:hover,
  &.ps--clicking {
    opacity: 1 !important;

    @media (min-width: $md) {
      width: 6px;
      border-radius: 12px;
    }
  }
}

.ps__thumb-y {
  width: 100%;
  max-width: 100%;
  background: var(--black) !important;
  opacity: 1 !important;
  right: 0;
  border-radius: 5px;
}