.company{
  &-info{
    padding: 20px;
    height: max-content;
    @media (max-width: $xxl) {
      padding: 15px;
    }
    @media (max-width: $sm) {
      padding: 20px;
      display: flex;
      flex-direction: column;
    }
    border: 1px solid #E8E8E8;
    &__image{
      max-width: 140px;
      margin: 0 auto 20px auto;
      display: block;
      @media (max-width: $xxl) {
        max-width: 80px;
      }
      @media (max-width: $lg) {
        display: none;
      }
      @media (max-width: $sm) {
        display: block;
        margin-bottom: 15px;
        order: 0;
      }
    }
    &__button{
      margin-top: 20px;
      width: 100%;
      @media (max-width: $xxl) {
        margin-top: 15px;
        padding: 16px 20px;
      }
      @media (max-width: $lg) {
       display: none;
      }
    }
    &__head{
      &-tablet{
        display: none;
        @media (max-width: $lg) {
          display: flex;
          margin-bottom: 15px;
          align-items: center;
        }
        @media (max-width: $sm) {
          order: 2;
          margin-top: 20px;
          flex-wrap: wrap;
          margin-bottom: 0;
        }
        &__image{
          @media (max-width: $lg) {
            max-width: 80px;
            margin-right: 15px;
            flex: 1;
          }
          @media (max-width: $sm) {
           display: none;
          }
        }
        &__info{
          @media (max-width: $lg) {
            flex: 2;
          }
          @media (max-width: $sm) {
            width: 100%;
            flex: auto;
          }
          &-stat{
            @media (max-width: $lg) {
              display: flex;
              font-size: 12px;
              line-height: 15px;
              align-items: center;
              margin-bottom: 10px;
            }
            @media (max-width: $sm) {
              justify-content: center;
            }
            h6{
              @media (max-width: $lg) {
                font-size: 12px;
                line-height: 15px;
                margin-top: 2px;
                margin-right: 5px;
              }
              @media (max-width: $sm){
                width: auto;
              }
            }
          }
          &-rating{
            @media (max-width: $lg) {
              display: flex;
              font-size: 12px;
              line-height: 15px;
            }
            @media (max-width: $sm) {
              justify-content: center;
            }
            span{
              @media (max-width: $lg) {
                font-weight: 700;
                color: $blue;
                margin-left: 5px;
              }
            }
          }
        }
        &__button-wrap{
          @media (max-width: $lg) {
            flex: 2;
            display: flex;
            justify-content: flex-end;
          }
          @media (max-width: $sm) {
            flex: auto;
            width: 100%;
            display: flex;
            margin-top: 15px;
           justify-content: center;
          }
        }
        &__button{
          @media (max-width: $lg) {
            padding: 17px 24px;
            line-height: 17px;
          }
        }
      }
    }
    &--row{
      border-left: 10px solid $blue_2;
      padding: 20px 30px;
      @media (max-width: $xxl) {
        padding: 20px 15px;
      }
      @media (max-width: $lg) {
        padding: 15px 30px;
        margin-bottom: 30px;
      }
      @media (max-width: $sm) {
        padding: 20px 0 20px 20px;
        margin-bottom: 40px;
      }
      &__title{
        margin-top: 0;
        margin-bottom: 10px;
      }
      &__price{
        font-family: $Inter;
        font-weight: 500;
        font-size: 18px;
        line-height: 125%;
        margin-bottom: 21px;
        @media (max-width: $xxl) {
          margin-bottom: 16px;
        }
      }
      &__data{
        font-size: 14px;
        line-height: 20px;
        font-family: $Inter;
        font-weight: 400;
        margin: 20px 0;
        @media (max-width: $xxl) {
          font-size: 12px;
          margin: 15px 0 5px 0;
        }
        &-info{
          margin-bottom: 10px;
          >span{
            margin-right: 10px;
            &:last-child{
              margin-right: 0;
            }
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &__addidional{
        font-family: $Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: $grey;
        @media (max-width: $xxl) {
          font-size: 10px;
        }
         >span{
           &:after{
             position: relative;
             display: inline-block;
             margin: 0 5px;
             color: $grey_line;
             content: '|';
           }
           &:last-child{
             &:after{
               display: none;
             }
           }
         }
      }
    }
  }
}