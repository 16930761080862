.cc-news-sidebar-card {
  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: grid;
    grid-template-columns: auto 120px;
    column-gap: 20px;
    row-gap: 10px;
    grid-template-areas:
      "header img"
      "title img"
      "footer footer"
    ;

    @media (max-width: $xxl ) {
      grid-template-columns: auto 60px;
      column-gap: 10px;
    }
  }

  &--post {
    .cc-news-sidebar-card {
      &__box {
        grid-template-areas:
          "title img"
          "footer footer"
        ;
      }
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__header {
    grid-area: header;
  } 

  &__footer {
    grid-area: footer;
  }

  &__title {
    grid-area: title;
  }

  &__img {
    grid-area: img;
  }
}