.search-hero {
  width: 100%;

  &__form {
    &-box {
      display: grid;
      grid-template-columns: 4.1fr 1fr;
      gap: var(--column-gap);
    }
  }

  &__input {
    position: relative;
  }

  &__field {

  }

  &__btn {
    width: 100%;

    &-wrap {

    }
  }

  &__icon-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #969696;

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
      }
    }
  }

  &__footer {
    margin-top: 20px;
    margin-bottom: 25px;

    @media (max-width: $xxl ) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
}