.contact-links{
  padding-top: 0;
  @media (max-width: $xxl) {
    padding-top: 70px;
  }
  @media (max-width: $lg){
    padding-top: 40px;
  }
  @media (max-width: $sm){
    padding-top: 40px;
  }
  &__head{
    &-image{
      margin: 0 auto;
      display: block;
    }
    &-title{
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 125%;
      text-align: center;
      margin-top: 30px;
      @media (max-width: $xxl) {
        margin-top: 20px;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  &__list{
    margin-top: 60px;
    @media (max-width: $xxl) {
      margin-top: 40px;
    }
    @media (max-width: $sm){
      margin-top: 20px;
    }
  }
}