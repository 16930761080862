.maximize{
  position: absolute;
  padding: 15px;
  display: block;
  top: 20px;
  right: 20px;
  background: rgba(29,29,29,.5);
  border-radius: 50%;
  transition: all .3s ease;
  @media (max-width: $xxl) {
    padding: 9px;
    top: 30px;
    right: 30px;
  }
  @media (max-width: $sm) {
    top: 20px;
    right: 20px;
  }
  &:hover{
    background: rgba(29,29,29,1);
  }
  svg{
    fill: $white;
    @media (max-width: $xxl) {
      width: 12px;
      height: 12px;
    }
  }
}