.cc-channelchek-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 60px;
    flex: 1 1;
    position: relative;
    z-index: 2;
    pointer-events: none;

    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }
  }

  &__footer {
    position: relative;
    z-index: 2;
    pointer-events: none;

    .row {
      @media (max-width: $xxl ) {
        --column-gap: 10px;
      }
    }
  }

  &__title {
    margin-top: 10px;
  }

  &__desc {
    margin-top: 10px;
  }

  &__img {
    position: relative;

    @media (max-width: $xxl ) {
      width: 90px;
      height: 53px;
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__play-btn {
    --cc-play-size: 30px;

    @media (max-width: $sm) {
      --cc-play-size: 40px;
    }
  }

  @media (max-width: $sm) {
    &.cc-channelchek-card--mobile-big {
      .cc-channelchek-card {
        &__img {
          width: 100%;
          height: auto;
          padding: 0;
          margin: 0;
          margin-bottom: 10px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}