.cc {
  &-box {
    border: 1px solid var(--black);
    border-top: 5px solid var(--orange); 
    background-color: var(--white);
  
    &--current-color {
      background-color: var(--current-body-color);
    }
  } 

  &-icon {
    --cc-icon-size: 40px;

    width: var(--cc-icon-size);
    height: var(--cc-icon-size);
    color: var(--white);
    border-radius: calc(var(--cc-icon-size) / 5);
    background: var(--grey);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $xxl ) {
      --cc-icon-size: 30px;
    }

    svg {
      width: calc(var(--cc-icon-size) /2);
      height: calc(var(--cc-icon-size) /2);
    }

    &--company {
      background: var(--blue);
    }

    &--news {
      background: var(--orange);
    }

    &--video {
      background: var(--turquoise);
    }

    &--research {
      background: var(--violet);
    }
    
    &--cc-in {
      background: var(--yellow);
    }
    
    &--movers {
      background: var(--green);
    }
    
    &--favorites {
      background: var(--red);
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: auto 320px;
    gap: 20px;
    width: 100%;

    @media (max-width: $xxl ) {
      grid-template-columns: auto 240px;
    }

    @media (max-width: $lg ) {
      grid-template-columns: auto 275px;
    }
    
    @media (max-width: $sm ) {
      grid-template-columns: 1fr;
    }

    &--reverse {
      grid-template-columns: 320px auto;

      @media (max-width: $xxl ) {
        grid-template-columns: 240px auto;
      }

      @media (max-width: $lg ) {
        grid-template-columns: 275px auto;
      }
      
      @media (max-width: $sm ) {
        grid-template-columns: 1fr;
      }
    }

    &--home {
      @media (max-width: $xxl ) {
        grid-template-columns: auto 240px;
      }
  
      @media (max-width: $lg ) {
        grid-template-columns: auto 215px;
      }
      
      @media (max-width: $sm ) {
        grid-template-columns: 1fr;
      }
    }

    &-body {
      display: flex;

      &__item {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        border-bottom: 1px solid var(--light-grey-line);

        @media (max-width: $xxl ) {
          padding-bottom: 15px;
        }

        @media (max-width: $sm) {
          padding-bottom: 20px;
        }
      }
    }

    &-sidebar {
      display: flex;
      flex-direction: column;
    }
  }

  &-homepage {
    &-section-link {
      color: var(--black);
      transition: color .2s ease-in-out;

      &:hover {
        @media (min-width: $md) {
          text-decoration: none;
        }
      }

      &--violet {
        &:hover {
          @media (min-width: $md) {
            color: var(--violet);
          }
        }
      }
      &--orange {
        &:hover {
          @media (min-width: $md) {
            color: var(--orange);
          }
        }
      }
      &--yellow {
        &:hover {
          @media (min-width: $md) {
            color: var(--yellow);
          }
        }
      }
      &--green {
        &:hover {
          @media (min-width: $md) {
            color: var(--green);
          }
        }
      }
      &--green {
        &:hover {
          @media (min-width: $md) {
            color: var(--green);
          }
        }
      }
      &--blue {
        &:hover {
          @media (min-width: $md) {
            color: var(--blue);
          }
        }
      }
      &--turquoise {
        &:hover {
          @media (min-width: $md) {
            color: var(--turquoise);
          }
        }
      }
    }

    &-content {
      &__video-wrap {
        display: flex;
        flex-direction: column;
      }
    }

    &-videolist {
      &__line {
        max-height: 450px;

        @media (max-width: $xxl ) {
          max-height: none;
        }
      }
    }

    &-sidebar {
      display: flex;
      flex-direction: column;
      height: 100%;

      @media (max-width: $lg ) {
        display: grid;
        grid-template-columns: calc(50% - var(--column-gap)) 1px calc(50% - var(--column-gap));
        gap: var(--column-gap);
      }

      &__wrap {
        flex: 1 1;
        display: flex;
        flex-direction: column;
      }

      &-scrollbar {
        flex: 1 1;
        padding-right: 20px;

        &--research {
          max-height: 250px;

          @media (max-width: $xxl ) {
            max-height: 313px;
          }

          @media (max-width: $lg ) {
            max-height: 220px;
          }
        }
        
        &--movers {
          max-height: 320px;

          @media (max-width: $lg ) {
            max-height: 220px;
          }
        }
      }
    }
  }

  &-ibl-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1 1;
    display: flex;
    flex-direction: column;

    &--video {
      .cc-ibl-list {
        &__item {
          flex: none;

          @media (max-width: $xxl ) {
            flex: 1 1;
          }
        }
      }

      @media (max-width: $lg ) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: calc(var(--column-gap) * 2);

        .cc-ibl-list {
          &__item {
            position: relative;
            border-bottom: none;
            padding-bottom: 0;


            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: calc(var(--column-gap) * (-1));
              height: 100%;
              width: 1px;
              background: var(--light-grey-line);
            }
          }
        }
      }
    }

    &--video-last {
      .cc-ibl-list {
        &__item {
          flex: none;

          @media (max-width: $xxl ) {
            flex: 1 1;
          }
        }
      }

      @media (max-width: $lg ) {
        .cc-ibl-list {
          &__item {
            border-bottom: none;
            padding-bottom: 0;
            padding-left: var(--column-gap);

            &:first-child {
              display: none;
            }
          }
        }
      }
    }

    &__item {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--light-grey-line);
      flex: 1 1;
      display: flex;
      flex-direction: column;

      @media (max-width: $xxl ) {
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      @media (max-width: $sm) {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;

        @media (max-width: $xxl ) {
          margin-bottom: 15px;
          padding-bottom: 0; 
        }

        @media (max-width: $sm) {
          border-bottom: 1px solid var(--light-grey-line);
          margin-bottom: 20px;
          padding-bottom: 20px;
        }
      }
    }
  }

  &-page-search-form {
    position: relative;

    &__button {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 20px;
      height: 20px;
      color: var(--grey);
      transform: translateY(-50%);
      transition: color .2s ease-in-out;

      &:hover {
        color: var(--violet);
      }
    }
  }

  &-filter-wrap {
    &__box {
      border-color: var(--light-grey-line);
      border-width: 1px 0 1px 0;
      border-style: solid;
      margin: -1px 0;

      &:last-child {
        @media (max-width: $lg ) {
          border-bottom: none;
        }
      }
    }

    &-accordion {
      &.accordion--active {
        .cc-filter-wrap-accordion {
          &__icon {
            transform: rotateZ(-90deg);
          }
        }
      }

      &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--black);
        padding: 20px 0;

        @media (max-width: $xxl ) {
          padding: 15px 0;
        }

        &:hover {
          text-decoration: none;

          @media (min-width: $md) {
            color: var(--violet);
          }
        }
      }

      &__text {
        color: currentColor;
      }

      &__icon {
        color: var(--blue);
        transform: rotateZ(90deg);
        transition: transform .2s ease-in-out;
      }

      &__box {
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
  }

  &-inner-page {
    &-sidebar {
      padding-bottom: 20px;

      @media (max-width: $xxl ) {
        margin-bottom: 15px;
      }

      @media (max-width: $lg ) {
        display: none;
      }

      &__wrap {
        padding-top: 40px;

        @media (max-width: $xxl ) {
          padding-top: 30px;
        }
      }
    }

    &__line {
      max-height: calc(100% - 80px);
      margin-bottom: 80px;

      @media (max-width: $xxl ) {
        max-height: calc(100% - 65px);
        margin-bottom: 65px;
      }
      
      @media (max-width: $sm ) {
        max-height: calc(100% - 70px);
        margin-bottom: 70px;
      }
    }
  }

  &-list-borders {
    list-style: none;
    padding: 0;
    margin: 0;

    &--notop {
      .cc-list-borders__item {
        &:first-child {
          border-top: none ;
        }
      }
    }

    &__item {
      padding: 21px 0;
      margin-bottom: -1px;
      border-width: 1px 0 1px 0;
      border-color: var(--light-grey-line);
      border-style: solid;

      @media (max-width: $xxl ) {
        padding: 16px 0;
      }

      @media (max-width: $sm) {
        padding: 20px 0;
      }
    }
  }

  &-sidebar-featured {
    &__header {
      padding-bottom: 20px;

       @media (max-width: $xxl ) {
         padding-bottom: 15px;
       }
    }

    &__star {
      fill: var(--yellow); 
    }
  }

  &-post-auth {
    display: grid;
    grid-template-columns: 40px 1fr;
    column-gap: 10px;
    align-items: center;
    text-decoration: none !important;

    &__name {
      color: var(--black);
      text-decoration: none;
    }

    &__desc {
      text-decoration: none;
    }

    &__img {
      grid-row: 1/3;
    } 

    &:hover {
      text-decoration: none;
      
      .cc-post-auth__name {
        text-decoration: underline;
      }

      .cc-post-auth__desc {
        text-decoration: none;
      }
    }

    &--collapse {
      @media (max-width: $sm) {
        grid-template-columns: 1fr;
        text-align: center;

        .cc-post-auth {
          &__img {
            grid-row: auto;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  &-terms-list {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr;
    width: 100%;

    &__link {
      border-radius: 5px;
      padding: 15px 20px;
      color: var(--black);
      display: block;
      transition: background .2s ease-in-out;

      &:hover {
        @media (min-width: $md) {
          text-decoration: none;
          background: var(--light-grey-bgr);
        }
      }

      &--active {
        background: var(--light-grey-bgr);
      }
    }
  }
}