.banner{
  display: flex;
  padding: 60px;
  border-top: 5px solid $orange;
  border-right:1px solid $black;
  border-left:1px solid $black;
  border-bottom: 1px solid $black;
  @media (max-width: $xxl) {
    padding: 50px 60px;
  }
  @media (max-width: $lg) {
    padding: 30px 40px;
  }
  @media (max-width: $sm) {
    flex-wrap: wrap;
    padding: 150px 40px 40px 40px;
  }
  &__content{
    width: 50%;
    position: relative;
    @media (max-width: $sm) {
      width: 100%;
    }
    &-title{
      font-weight: 300;
      max-width: 500px;
      @media (max-width: $xxl) {
        max-width: 270px;
        font-size: 26px;
        line-height: 32px;
      }
      @media (max-width: $sm) {
        max-width: 100%;
        text-align: center;
      }
    }
    &-text{
      max-width: 420px;
      @media (max-width: $xxl) {
        font-size: 14px;
        line-height: 24px;
        max-width: 270px;
      }
      @media (max-width: $sm) {
        max-width: 100%;
        text-align: center;
      }
    }
    &:before{
      position: absolute;
      content: '';
      background: url("/img/line-banner.svg") no-repeat;
      background-size: contain;
      top:33%;
      right: 0;
      display: block;
      width: 327px;
      height: 110px;
      @media (max-width: $xxl) {
        width: 161px;
        height: 53px;
        right: 45px;
      }
      @media (max-width: $lg) {
        display: none;
      }
      @media (max-width: $sm) {
        display: block;
        width: 100%;
        top: -90px;
        left: 10%;
      }
    }
    &:after{
      position: absolute;
      content: '';
      background: url("/img/paper-airplane.svg") no-repeat;
      background-size: contain;
      height: 60px;
      top: 12%;
      width: 60px;
      right: -30px;
      display: block;
      @media (max-width: $xxl) {
        width: 30px;
        height: 30px;
        top: 15%;
        right: 20px;
      }
      @media (max-width: $lg) {
        display: none;
      }
      @media (max-width: $sm) {
        display: block;
        right: auto;
        left: 62%;
        top: -120px;
        width: 40px;
        height: 40px;
      }
      @media (max-width: $less){
        left: 85%;
      }
    }
  }
  &__form{
    width: 50%;
    padding-left: 100px;
    display: flex;
    align-items: center;
    @media (max-width: $xxl) {
      padding-left: 0;
    }
    @media (max-width: $sm) {
      width: 100%;
      flex-wrap: wrap;
    }
    &-input{
      margin-right: 20px;
      width: calc(50% - 10px);
      @media (max-width: $xxl) {
        width: calc(50% - 7.5px);
        margin-right: 15px;
      }
      @media (max-width: $sm) {
        width: 100%;
        margin-right: 0;
      }
    }
    &-submit{
      width: calc(50% - 10px);
      padding: 16px 10px;
      line-height: 140%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $xxl) {
        width: calc(50% - 7.5px);
        padding: 4px 10px;
      }
      @media (max-width: $lg) {
        padding: 4px 10px;
      }
      @media (max-width: $sm) {
        width: 100%;
        margin-top: 10px;
      }
      svg{
        margin-left: 10px;
      }
    }
  }
}