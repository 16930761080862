.profile {

  &-complete {
    text-align: center;
    padding: 40px;
    border: 1px solid var(--light-grey-line);

    &__title {
      margin-top: 15px;
      margin-bottom: 10px;
    }
  }

  &__section {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid var(--light-grey-line);

    @media (max-width: $xxl ) {
      padding-top: 30px;
      margin-top: 30px;
    }

    @media (max-width: $sm) {
      padding-top: 20px;
      margin-top: 20px;
    }
  }

  &__title {
    margin-bottom: 40px;

    @media (max-width: $xxl ) {
      margin-bottom: 30px;
    }

    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
  }

  &-table {
    display: grid;
    gap: var(--column-gap);
    width: 100%;

    &__heading {
      padding-top: 5px;
    }

    &__btn {
      margin-top: 20px;

      @media (max-width: $sm) {
        margin-top: 0;
      }
    }

    &__item {
      display: grid;
      grid-template-columns: 30% 1fr;
      gap: 40px;

      @media (max-width: $xxl ) {
        gap: 30px;
      }

      @media (max-width: $sm) {
        gap: 10px;
        grid-template-columns: 1fr;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__field {
      max-width: 320px;

      @media (max-width: $sm) {
        max-width: none;
      }
    }
    
    &__field-big {
      max-width: 680px;

      @media (max-width: $sm) {
        max-width: none;
      }
    }
  }
}