.more{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $grey_light;
  border-radius: 50%;
  transition: all .3s ease;
  &:hover{
    opacity: .8;
  }
}