.cc-homepage-table-movers {
  tr {
    border-bottom: 1px solid var(--light-grey-line);
    position: relative;

    &:last-child {
      border-bottom: none;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px 0;
    }
  }

  td {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &__heading {
    padding-bottom: 10px;

    &--symbol {
      width: 54%;

      @media (max-width: $xxl ) {
        width: 45%;
      }

      @media (max-width: $sm) {
        width: 60%;
      }
    }

    &--change {
      width: 52px;
    }
  }
}