.cc-movers-table {
  --cc-movers-ratio: 1.7fr 1.2fr 1fr 1fr 20px;

  @media (max-width: $xxl ) {
    --cc-movers-ratio: 3.24fr 1.85fr 1.44fr 1fr 20px;
  }
  
  @media (max-width: $sm ) {
    --cc-movers-ratio: auto 1fr 20px;
  }
  
  @media (max-width: $lg ) {
    --cc-movers-ratio: 3.31fr 1.49fr 1.05fr 1fr 20px;
  }

  &__pagination {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid var(--light-grey-line);
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }

    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      margin-bottom: 20px;
    }

    &-link {
      background: var(--light-grey-bgr);
      border-radius: 5px;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: var(--black);
      border: 1px solid var(--light-grey-line);
      transition: all .2s ease-in-out;

      @media (max-width: $xxl ) {
        padding: 0 15px;
        height: 40px;
      }

      &:hover {text-decoration: none;}

      &--green {--pagination-link-color: var(--green);}
      &--red {--pagination-link-color: var(--red);}
      &--violet {--pagination-link-color: var(--violet);}

      &--active {
        border-color: var(--pagination-link-color);
        background: var(--white);
      }

      &:hover {
        @media (min-width: $md) {
          border-color: var(--pagination-link-color);
          background: var(--white);
        }
      }
    }

    &-title {
      padding: 0 10px;
      flex: 1 1;
    } 

    &-icon {
      fill: var(--pagination-link-color);
      transition: fill .2s ease-in-out;

      @media (max-width: $xxl ) {
        height: 16px;
        width: 16px;
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: var(--cc-movers-ratio);
    padding: var(--column-gap);
    border-bottom: 1px solid var(--black);

    @media (max-width: $sm) {
      display: none;
    }
  }

  &__body {
    margin-bottom: 20px;
    @media (max-width: $xxl ) {margin-bottom: 15px;}
    @media (max-width: $sm) {margin-bottom: 20px;}
  }
}