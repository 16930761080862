.tags{
  display: flex;
  &__item{
    font-family: $Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
    padding: 5px 10px;
    border: 1px solid #E8E8E8;
    border-radius: 5px;
    @media (max-width: $xxl) {
      font-size: 12px;
    }
    span{
      &:nth-child(1){
        margin-right: 5px;
        margin-left: 5px;
      }
      &:last-child{
        margin-left: 5px;
      }
    }
    &:last-child{
      margin-right: 0;
    }
  }
}