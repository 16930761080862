.senior{
  &__head{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &-image{
      margin-right: 20px;
    }
    &-name{
      &__title{
        font-family: $Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 125%;
        color: #000000;
        margin-bottom: 5px;
      }
      &__posotion{
        font-family: $Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $grey;
      }
    }
  }
  &__content{
    font-size: 16px;
    line-height: 30px;
    color: #000000;
  }
  &__footer{
    margin-top: 20px;
    display: flex;
  }
  &__email{
    line-height: 30px;
    padding: 10px 20px;
    background: $grey_light;
    border-radius: 5px;
    color: $black;
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
    svg{
      fill: $blue;
      margin-right: 10px;
    }
  }
}