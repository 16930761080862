.slider{
  &__arrow{
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $grey_line;
    cursor: pointer;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      width: 40px;
      height: 40px;
    }
    svg{
      @media (max-width: $xxl) {
        width: 15px;
        height: 15px;
      }
    }
    &.swiper-button-disabled{
      opacity: .5;
    }
  }
}