.cc-homepage-sidebar-research-card {
  max-width: 100%;
  padding: 9px 20px 9px 10px;
  border: 1px solid var(--light-grey-bgr);
  border-radius: 8px;
  transition: all .2s ease-in-out;
  color: var(--black);

  @media (max-width: $xxl) {
    padding: 10px 15px 10px 10px; 
  }

  &__img {
    height: 36px;
    width: 36px;
    object-fit: contain;

    &-box {
      border: 1px solid var(--light-grey-line);
      border-radius: 8px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--white);
    }
  }

  &:hover {
    background: var(--white);
    color: var(--violet);
  }
}

.cc-homepage-sidebar-research-wrap {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}