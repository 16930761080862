.stars{
  position: relative;
  display: inline-flex;
  svg{
    min-width: 20px;
    height: 20px;
    @media (max-width: $xxl) {
      width: 16px;
      height: 16px;
    }
  }
  &--static{
    position: relative;
    z-index: 10;
    display: inline-flex;
    svg{
      fill: $grey;
      margin-right: 5px;
      width: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &--active{
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    display: inline-flex;
    svg{
      flex-shrink: 0;
      fill: $green;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}