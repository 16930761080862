.hero{
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
  @media (max-width: $sm){
    background-position: center;
    background-image: url("/img/bgr_mob.png")!important;
  }
  &__wrap{
    display: flex;
    align-items: center;
    min-height: calc(100vh - 132px);
    @media (max-width: $xxl) {
      min-height: 367px;
      height: auto;
      padding: 60px 0;
    }
    @media (max-width: $sm){
      min-height: 420px;
    }
  }
  &__content{
    position: relative;
    border-left: 10px solid $blue_2;
    padding: 70px 0 70px 40px;
    color: $white;
    width: 100%;
    &--green{
      border-left: 10px solid $green;
      .hero__scroll-down{
        &:hover{
          background: $green;
        }
      }
    }
    &--purple{
      border-left: 10px solid $purple;
      .hero__scroll-down{
        &:hover{
          background: $purple;
        }
      }
    }
    &--orange{
      border-left: 10px solid $orange;
      .hero__scroll-down{
        &:hover{
          background: $orange;
        }
      }
    }
    &--turquoise{
      border-left: 10px solid $Turquoise;
      .hero__scroll-down{
        &:hover{
          background: $Turquoise;
        }
      }
  }
    &--yellow{
      border-left: 10px solid $yellow;
      .hero__scroll-down{
        &:hover{
          background: $yellow;
        }
      }
    }
    @media (max-width: $xxl) {
      padding: 30px 0 30px 49px;
    }
    @media (max-width: $sm){
      border-left: none;
      padding: 0;
      text-align: center;
    }
    &-title{
      font-style: normal;
      font-weight: 300;
      font-size: 60px;
      line-height: 75px;
      margin-bottom: 20px;
      max-width: 500px;
      @media (max-width: $xxl) {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 15px;
        max-width: 370px;
      }
      @media (max-width: $sm){
        font-size: 26px;
        line-height: 33px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-text{
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      max-width: 500px;
      @media (max-width: $xxl) {
        font-size: 14px;
        line-height: 24px;
      }
      @media (max-width: $sm) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__scroll{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    @media (max-width: $sm){
      top: auto;
      bottom: -80px;
      display: block;
      height: auto;
      right: calc(50% - 20px);

    }
    &:after{
      position: absolute;
      background: rgba(255,255,255,1);
      opacity: .2;
      top: 80px;
      right: 30px;
      width: 1px;
      height: 150%;
      display: block;
      content: '';
      z-index: 1;
      @media (max-width: $xxl) {
        right: 20px;
        top: 60px;
      }
      @media (max-width: $sm){
        display: none;
      }
    }
    &-current{
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      transform: rotate(-180deg);
      writing-mode: vertical-rl;
      text-orientation: mixed;
      display: flex;
      align-items: center;
      z-index: 5;
      &:after{
        position: absolute;
        bottom: 100%;
        display: block;
        width: 1px;
        height: 40px;
        content: '';
        background: rgba(0,0,0,.5);
        @media (max-width: $xxl) {
          height: 20px;
        }
        @media (max-width: $sm) {
          display: none;
        }
      }
      @media (max-width: $xxl) {
        font-size: 14px;
        line-height: 125%;
      }
      @media (max-width: $sm){
        display: none;
      }
    }
    &-down{
      border: 1px solid rgba(255,255,255,.3);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(0,0,0,.3);
      transition: all .3s ease;
      z-index: 8;
      @media (max-width: $xxl) {
        width: 40px;
        height: 40px;
      }
      &:hover{
        background: $blue;
      }
      svg{
        fill: $white;
        @media (max-width: $xxl) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &--second{
    padding-top: 40px;
    padding-bottom: 0;
    @media (max-width: $xxl){
      padding-top: 30px;
      padding-bottom: 0;
    }
    @media (max-width: $sm){
      padding-top: 20px;
      padding-bottom: 0;
    }
    &__wrap{
      display: flex;
      margin-top: 80px;
      border-bottom: 1px solid $black;
      padding-bottom: 100px;
      @media (max-width: $xxl){
        margin-top: 30px;
        padding-bottom: 60px;
      }
      @media (max-width: $sm){
        margin-top: 20px;
        flex-wrap: wrap;
        padding-bottom: 40px;
      }
    }
    &__image{
      flex: 1;
      @media (max-width: $sm){
        width: 100%;
        flex: auto;
        margin-bottom: 40px;
      }
    }
    &__content{
      flex: 1;
      padding-top: 40px;
      border-top: 10px solid $blue;
      @media (max-width: $xxl){
        padding-top: 30px;
      }
      @media (max-width: $sm){
        width: 100%;
        flex: auto;
        padding-top: 40px;
      }
      p{
        margin-top: 0;
        &:last-child{
          margin-bottom: 0;
        }
        @media (max-width: $xxl){
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  &--discover{
    @media (max-width: $sm){
      background-image: url("/img/bgr_mob_1.png")!important;
    }
  }
  &--noblecon{
    @media (max-width: $sm){
      background-image: url("/img/bgr_mob_2.png")!important;
    }
  }
  &--college{
    @media (max-width: $sm){
      background-image: url("/img/bgr_mob_3.png")!important;
    }
  }
  &--offerings{
    @media (max-width: $sm){
      background-image: url("/img/bgr_mob_4.png")!important;
    }
  }
  &--noble-related{
    @media (max-width: $sm){
      background-image: url("/img/bgr_mob_5.png")!important;
    }
  }
}