.video-page {
  &__descitpion-item {
    display: none;
    margin-top: 30px;

    @media (max-width: $lg ) {
      display: block;
    }

    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }

  &__item {
    margin-bottom: 40px;

    @media (max-width: $xxl) {
      margin-bottom: 30px;
    }

    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
  }

  &__header  {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--black);

    .social-list-analyst {
      @media (max-width: $sm) {
        justify-content: flex-start;
        margin-top: 20px;
      }
    }

    @media (max-width: $xxl ) {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    @media (max-width: $sm) {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  &__box {
    border-top: 10px solid var(--turquoise);
    padding-top: 40px;

    @media (max-width: $xxl ) {
      padding-top: 30px;
    }

    @media (max-width: $sm) {
      margin: 0 calc(var(--container-side-padding) * -1);
      padding: 0 var(--container-side-padding);
      padding-top: 20px;
    }
  }

  &__related {
    &-title {
      @media (max-width: $sm) {
        font-size: 16px;
      }
    }

    &-header {
      margin-top: 60px;
      padding-top: 40px;
      padding-bottom: 20px;
      border-top: 1px solid var(--black);

      @media (max-width: $xxl ) {
        padding-bottom: 15px;
        padding-top: 30px;
        margin-top: 30px;
      }

      @media (max-width: $sm) {
        padding: 20px 0;
        margin-top: 40px;
      }
    }
  }
}

.video-player {
  &__img {
    width: 100%;
  }

  &__item {
    width: 100%;
  }

  &--active {
    .video-player {
      &__img {
        &::after {
          display: none;
        }
      }
    
      &__link {
        display: none;
      }
    
      &__play-btn {
        display: none;
      }
    }
  }
}