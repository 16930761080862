.news{
  &__form{
    display: flex;
    align-items: center;
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &-label{
      margin-right: 20px;
      display: flex;
      align-items: center;
      font-family: $Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      @media (max-width: $sm) {
        flex-wrap: wrap;
        width: calc(50% - 5px);
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:first-child{
        @media (max-width: $sm){
          margin-right: 10px;
        }
      }
      input{
        font-family: $Merr;
        padding: 10px;
        margin-left: 10px;
        @media (max-width: $sm){
          margin-left: 0;
          margin-top: 10px;
          width: 100%;
        }
      }
    }
    .btn-primary{
      margin-right: 20px;
    }
  }
  &__item{
    padding: 20px 0;
    border-bottom: 1px solid $grey_line;
    &-head{
      .date{
        font-family: $Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey_line;
      }
    }
    &-body{
      padding-top: 20px;
      &__info{
        font-family: $Inter;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        margin-bottom: 10px;
      }
      &__content{
        &-title{
          font-size: 16px;
          line-height: 20px;
          text-decoration-line: underline;
          color: $blue;
          margin-bottom: 10px;
          display: block;
          transition: all .3s ease;
          &:hover{
            color: $orange;
          }
        }
        &-text{
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  &__more{
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &--more{
    &__item{
      &:last-child{
        border-bottom: none;
      }
    }
    &__link{
      padding: 40px 0;
      display: flex;
      color: $black;
      border: 1px solid transparent;
      transition: all .3s ease;
      @media (max-width: $xxl) {
        padding: 30px 0;
      }
      @media (max-width: $lg){
        padding: 20px 0;
      }
      &:hover{
        text-decoration: none;
        .news--more{
          &__link{
            &-image{
              &:after{
                opacity: 1;
              }
            }
            &-content{
              h4{
                color: $blue;
                text-decoration: underline;
              }
              .link{
                opacity: 1;
                visibility: visible;
                @media (max-width: $lg){
                  position: relative;
                }
              }
            }
          }
        }

      }
      &-image{
        transition: all .3s ease;
        margin-right: 40px;
        position: relative;
        min-width: 200px;
        img{
          object-fit: cover;
        }
        @media (max-width: $xxl) {
          margin-right: 30px;
          max-width: 150px;
          min-width: 150px;
        }
        @media (max-width: $lg){
          margin-right: 20px;
          max-width: 100px;
          min-width: 100px;
          height: 64px;
        }
        &:after{
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          display: block;
          content: '';
          border: 10px solid $blue_2;
          transition: all .3s ease;
          opacity: 0;
          @media (max-width: $lg){
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            border: 3px solid $blue_2;
          }
        }
      }
      &-content{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4{
          margin-top: 0;
          margin-bottom: 10px;
          font-family: $Merr;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 23px;
          transition: all .3s ease;
          @media (max-width: $xxl) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .link{
          position: relative;
          color: $blue;
          font-family: $Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: all .3s ease;
          visibility: hidden;
          @media (max-width: $xxl) {
            font-size: 12px;
          }
          svg{
            fill: $blue;
            margin-left: 10px;
          }
        }
        &__text{
          font-size: 14px;
          line-height: 20px;
          @media (max-width: $xxl) {
            font-size: 12px;
          }
        }
      }
    }
  }
}