@import "btn-left-icon";
@import "btn-right-icon";
@import "icon-btn";
@import "ham";
@import "popup-search-btn";
@import "user-box";
@import "video-hover";
@import "filter-ham";
@import "filter-btn";
@import "like-btn";
@import "play-btn";
@import "btn-more";

.btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 38px;
  font-size: 14px;
  font-weight: 700;
  color: var(--white);
  background: var(--blue);
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid var(--blue);
  transition: all .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;

  &:hover {
    @media (min-width: $md) {
      border-color: var(--violet);
      background: var(--violet);
      text-decoration: none;
    }
  }

  @media (max-width: $xxl) {
    line-height: 34px;
    font-size: 12px;
  }
}

.btn-grey {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 38px;
  font-size: 14px;
  font-weight: 700;
  color: var(--blue);
  background: var(--light-grey-bgr);
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid var(--light-grey-bgr);
  transition: all .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;

  &:hover {
    @media (min-width: $md) {
      border-color: var(--violet);
      background: var(--violet);
      color: var(--white);
      text-decoration: none;
    }
  }

  @media (max-width: $xxl) {
    line-height: 34px;
    font-size: 12px;
  }
}

.btn-black {
  display: inline-grid;
  line-height: 38px;
  font-size: 14px;
  font-weight: 700;
  color: var(--balck);
  background: var(--white);
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid var(--light-grey-line);
  transition: all .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  grid-template-columns: auto auto;
  gap: 10px;
  align-items: center;
  text-decoration: none !important;

  &:hover {
    @media (min-width: $md) {
      border-color: var(--violet);
      background: var(--violet);
      color: var(--white);
      text-decoration: none;
    }
  }

  @media (max-width: $xxl) {
    line-height: 34px;
  }

  @media (max-width: $sm) {
    line-height: 38px;
  }
}

.btn-full-width {
  width: 100%;
}

.btn-icon-right {
  svg {
    margin-left: 8px;

    @media (max-width: $xxl ) {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
  }
}

.btn-icon-left {
  svg {
    margin-right: 8px;

    @media (max-width: $xxl ) {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
  }
}

.btn-primary-empty {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $Inter;
  line-height: 38px;
  font-size: 14px;
  font-weight: 600;
  color: var(--blue);
  background: transparent;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid var(--blue);
  transition: all .3s ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;

  &:hover {
    @media (min-width: $md) {
      border-color: var(--blue);
      background: var(--blue);
      text-decoration: none;
      color: var(--white);
    }
  }

  @media (max-width: $xxl) {
    line-height: 34px;
    font-size: 12px;
  }

  @media (max-width: $sm) {
    padding: 0 15px;
  }
}

.btn-big {
  line-height: 58px;
  padding: 0 24px;

  @media (max-width: $xxl ) {
    line-height: 38px;
  }
}

.link {
  &-blue {
    &-underline {
      color: var(--blue);
      text-decoration: underline;
      transition: all .2s ease-in-out;

      &:hover {
        @media (min-width: $md) {
          text-decoration: none;
        }
      }
    }
  }
}

.btn-disabled {
  cursor: default;
  color: var(--white);
  background: var(--light-grey-line);
  border-color: var(--light-grey-line);
  
  &:hover {
    @media (min-width: $md) {
      color: var(--white);
      background: var(--light-grey-line);
      border-color: var(--light-grey-line);
    }
  }
}