.sponsored-research{
  padding-top: 0;
  @media (max-width: $xxl) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: $sm) {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__head{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &-title{
      width: 100%;
      text-align: center;
      margin-top: 40px;
    }
  }
  &__schema{
    background: $blue;
    margin-top: 80px;
    padding: 100px 0;
    img{
      @media (max-width: $sm) {
        display: none;
      }
    }
    &--mobile{
      display: none;
      @media (max-width: $sm) {
        display: block!important;
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media (max-width: $xxl) {
      margin-top: 60px;
      padding: 75px 0 60px 0;
    }
    @media (max-width: $lg){
      padding: 60px 0;
    }
    @media (max-width: $sm) {
      margin-top: 40px;
      padding: 0;
    }
  }
  &__content{
    padding-top: 100px;
    display: flex;
    border-bottom: 1px solid $black;
    padding-bottom: 60px;
    @media (max-width: $xxl) {
      padding-top: 60px;
      padding-bottom: 80px;
    }
    @media (max-width: $lg){
      padding-top: 80px;
    }
    @media (max-width: $sm) {
      flex-wrap: wrap;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &-text{
      flex: 1;
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
      }
      p{
        @media (max-width: $xxl) {
          font-size: 14px;
          line-height: 24px;
        }
        &:first-child{
          margin-top: 0;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &-more{
      flex: 1;
      padding-left: 160px;
      @media (max-width: $xxl) {
        padding-left: 120px;
      }
      @media (max-width: $lg){
        padding-left: 80px;
      }
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
        padding-left: 0;
      }
      &__title{
        font-family: $Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 125%;
        @media (max-width: $lg){
          font-size: 18px;
          line-height: 125%;
          margin-bottom: 10px;
        }
        @media (max-width: $sm) {
          margin-top: 40px;
        }
      }
      &__list{
        list-style: none;
        padding-left: 0;
      }
    }
  }
}