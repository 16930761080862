.comments {
  width: 100%;

  &__header {
    display: grid;
    grid-template-columns: max-content  max-content;
    gap: 10px;
    padding: 40px 0;
    border-top: 1px solid var(--black);
    align-items: center;

    @media (max-width: $xxl ) {
      padding: 30px 0;
    }

    h4 {
      margin: 0;
      @media (max-width: $xxl ) {margin: 0;}
    }
  }

  &__count {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: var(--light-grey-bgr);
    }
  }

  &__form {
    margin-bottom: 40px;
    position: relative;

    @media (max-width: $xxl ) {
      margin-bottom: 30px;
    }

    @media (max-width: $sm) {
      margin-bottom: 40px;
    }

    &-field {
      padding-top: 10px;

      @media (max-width: $xxl ) {
        padding-top: 5px;
      }

      @media (max-width: $sm ) {
        padding-top: 10px;
      }
    }

    &-btn {
      position: absolute;
      bottom: 20px;
      right: 20px;

      @media (max-width: $xxl ) {
        bottom: 15px;
        right: 15px;
      }

      @media (max-width: $sm) {
        bottom: 20px;
        right: 20px;
      }

      button {
        padding: 0 30px;
      }
    }
  }

  &-footer {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 10px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    &__imgs {
      display: flex;
      align-items: center;
      padding-left: 5px;

      img {
        border-radius: 50%;
        border: 1px solid var(--white);
        margin-left: -5px;
      }
    }
  }

  &__body {
    overflow-y: auto;
    width: 100%;
  }
}

.comment {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 10px;

  @media (max-width: $xxl ) {
    gap: 15px;
  }

  &-body {
    &__header {
      display: grid;
      grid-template-columns: max-content  max-content;
      gap: 10px;
      margin-bottom: 10px;

      @media (max-width: $xxl ) {
        margin-bottom: 5px;
      }
    }

    &__body {
      margin-bottom: 10px;

      @media (max-width: $xxl ) {
        margin-bottom: 5px;
      }
    }

    &__reply-btn {
      margin-bottom: 20px;

      @media (max-width: $xxl ) {
        margin-bottom: 15px;
      }

      @media (max-width: $sm) {
        margin-bottom: 20px;
      }
    }
  }
}