.popup-search-btn {
  --size: 20px;

  height: var(--size);
  width: var(--size);
  overflow: hidden;
  display: flex;
  color: var(--black);

  svg {
    width: var(--size);
    height: var(--size);
    display: block;
  }

  &__box {
    transition: transform .2s ease-in-out;
    display: block;
  }

  &--active {
    .popup-search-btn__box {
      transform: translateY(calc(var(--size) * (-1)));
    }
  }
}