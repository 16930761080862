.news-article {
  &__header {
    padding-top: 40px;
    padding-bottom: 20px;
    border-top: 10px solid var(--orange);

    @media (max-width: $xxl ) {
      padding: 30px 0 15px;
    }

    @media (max-width: $sm) {
      padding: 20px var(--container-side-padding) 10px;
      margin: 0 calc(var(--container-side-padding) * -1);
    }
  }

  &__title {
    margin-bottom: 30px;
    max-width: 1040px;

    @media (max-width: $xxl ) {
      margin-bottom: 25px;
    }

    @media (max-width: $sm) {
      margin-bottom: 10px;
    }
  }

  &__date {
    @media (max-width: $sm) {
      margin-bottom: 15px;
    }
  }

  &__box {
    padding-bottom: 40px;
    border-bottom: 1px solid var(--black);

    @media (max-width: $xxl ) {
      padding-bottom: 30px;
    }

    @media (max-width: $sm) {
      padding-bottom: 20px;
    }
  }

  &__auth {
    @media (max-width: $sm) {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
}