.filings{
  &__form{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &-label{
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
    &-select{
      margin-left: 10px;
    }
  }
}