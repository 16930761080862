.header-search {
  position: relative;
  width: 100%;

  @media (max-width: $xxl ) {
    padding: 0 var(--column-gap);
  }

  @media (max-width: $lg ) {
    padding: 0;
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
    color: var(--grey);
    transition: color .2s ease-in-out;
    height: 20px;
    width: 20px;

    @media (max-width: $xxl ) {
      right: calc(11px + var(--column-gap));
    }

    @media (max-width: $lg ) {
      right: 11px;
    }

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
      }
    }
  }
}