.modal{
  &-hint{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    &__wrap{
      position: relative;
      background: $white;
      padding: 40px;
      border-top: 10px solid $blue_2;
      font-family: $Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      width: 520px;
      max-width: 100%;
      margin: 10% auto;
      animation-name: animatetop;
      animation-duration: 0.4s
    }
    &__content{

    }
    .btn--close{
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}