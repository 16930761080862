.cc-news-card {
  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: grid;
    grid-template-columns: 1.83fr 1.16fr 1fr;
    column-gap: 40px;
    row-gap: 10px;
    grid-template-areas:
      "header header img"
      "title desc img"
      "footer footer img"
    ;

    @media (max-width: $xxl ) {
      grid-template-columns: repeat(3,1fr);
      column-gap: 30px;
    }

    @media (max-width: $lg ) {
      column-gap: 20px;
    }

    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      grid-template-areas: 
        "img"
        "header"
        "title"
        "desc"
        "footer"
      ;
    }
  }

  &__like-btn {
    pointer-events: all; 
  }

  &__header {
    grid-area: header;
  }

  &__footer {
    grid-area: footer;
  }

  &__title {
    grid-area: title;
  }

  &__desc {
    grid-area: desc;
    display: flex;
    align-items: center;
  }

  &__img {
    grid-area: img;
    display: flex;
    align-items: center;

    img {
      @media (max-width: $sm) {
        height: 240px;
      }

      @media (max-width: $xs) {
        height: 192px;
      }
    }
    
    * {
      width: 100%;
    }
  }
}