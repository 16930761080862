.user-box {
  --cc-user-box-image-size: 40px;

  display: grid;
  grid-template-columns: var(--cc-user-box-image-size) 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "img name"
    "img logout"
  ;
  gap: 0 10px;

  &__img {
    width: var(--cc-user-box-image-size);
    height: var(--cc-user-box-image-size);
    

    &-link {
      grid-area: img;

      &:hover {
        & + .user-box__name .user-box__name-link {
          @media (min-width: $md) {
            color: var(--violet);
          }
        } 
      }
    }
  }

  &__name {
    grid-area: name;

    &-link {
      color: var(--black);

      &:hover {
        @media (min-width: $md) {
          color: var(--violet);
          text-decoration: none;
        }
      }
    }
  }

  &__logout {
    grid-area: logout;

    &-link {
      color: var(--grey);

      &:hover {
        @media (min-width: $md) {
          color: var(--violet);
          text-decoration: none;
        }
      }
    }
  }
}