.duration{
  font-family: $Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $grey;
  display: flex;
  align-items: center;
  svg{
    margin-right: 5px;
  }
}