.reports{
  &__list{
    list-style: none;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4,1fr);
    margin-top: 40px;
    padding-left: 0;
    @media (max-width: $xxl) {
      margin-top: 30px;
      gap: 15px;
    }
    @media (max-width: $lg){
      grid-template-columns: repeat(3,1fr);
      gap: 20px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
}
.report{
  border: 1px solid $grey_line;
  border-top: 10px solid $grey_line;
  transition: all .3s ease;
  &:hover{
    box-shadow: 0px 5px 15px rgba(29, 29, 29, 0.15);
    border-top:10px solid $blue_2;
    text-decoration: none;
    .report__title{
      color: $blue;
      text-decoration: none;
    }
    .report__link{
      text-decoration: none;
    }
  }
  &__link{
    padding: 20px 20px 40px 20px;
    display: block;
    @media (max-width: $xxl) {
      padding: 15px;
    }
    @media (max-width: $sm) {
      padding: 18px 20px 20px 20px;
    }
  }
  &__image{
    display: block;
    margin: 0 auto 25px 0;
    @media (max-width: $xxl) {
      max-width: 40px;
      margin: 0 auto 15px 0;
    }
    @media (max-width: $lg){
      margin: 0 auto 73px 0;
    }
    @media (max-width: $sm) {
      margin: 0 auto 15px 0;
    }
  }
  &__date{
    font-family: $Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
    @media (max-width: $xxl) {
      margin-bottom: 5px;
      font-size: 10px;
    }
  }
  &__title{
    font-family: $Merr;
    font-style: normal;
    font-weight: 300;
    @media (max-width: $xxl) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}