.cc-news-large-card {
  &--turquoise {
    background: rgba(0, 204, 255, 0.05);
  }
  
  &--orange {
    background: rgba(255, 102, 0, 0.05);
  }

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: flex;

    @media (max-width: $sm) {
      display: grid;
      grid-template-areas: 
        "img"
        "wrap"
      ;
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__img {
    width: 500px;
    display: flex;
    grid-area: img;

    & > * {
      display: flex;
      width: 100%;
    }

    img {
      @media (max-width: $sm) {
        width: 100%;
        height: 240px;
      }

      @media (max-width: $xs) {
         height: 192px;
      }
    }

    @media (max-width: $xxl ) {
      width: 390px;
    }

    @media (max-width: $lg ) {
      width: 335px;
    }

    @media (max-width: $sm) {
      width: 100%;
    }
  }

  &__wrap {
    flex: 1 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    grid-area: wrap;

    @media (max-width: $xxl ) {
      padding: 30px;
    }

    @media (max-width: $xs) {
      padding: 10px 20px 20px;
    }
  }

  &__desc {
    flex: 1 1;
    margin-bottom: 10px;
  }

  &__title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}