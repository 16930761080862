.research-report {
  &__header {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 10px solid var(--violet);

    @media (max-width: $xxl ) {
      padding: 30px 0;
    }

    @media (max-width: $sm) {
      padding: 20px var(--container-side-padding);
      margin: 0 calc(var(--container-side-padding) * -1);
    }

    &--mobile-center {
      @media (max-width: $sm) {
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;

        .research-report__title {
          margin: 0;
          margin-top: 10px;
        }
      }
    }
  }

  &__foot {
    border-bottom: 1px solid var(--black);
    padding-bottom: 40px;

    @media (max-width: $xxl ) {
      padding-bottom: 30px;
    }

    @media (max-width: $sm) {
      padding-bottom: 20px;
    }
  }

  &__title {
    max-width: 960px;
    margin: 20px auto;

    @media (max-width: $xxl ) {
      margin: 10px auto;
      max-width: 870px;
    }

    @media (max-width: $lg) {
      max-width: 580px;
    }
  }

  &__auth {
    @media (max-width: $sm) {
      margin-bottom: 15px;
    }
  }

  &__add {
    @media (max-width: $sm) {
      margin-bottom: 20px;
    }
  }

  &__date {
    @media (max-width: $sm) {
      margin-bottom: 15px;
    }
  }

  &__logo {
    @media (max-width: $xxl ) {
      height: 30px;
      width: 30px;
    }
  }
}