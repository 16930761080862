.select-tabs {
  height: 410px;
  border-radius: 8px;
  border: 1px solid var(--light-grey-line);
  display: flex;
  padding: 5px 0;

  @media (max-width: $xs) {
    flex-direction: column;
  }

  &__tabs {
    border-right: 1px solid var(--light-grey-line);
    flex: 1 1;

    @media (max-width: $xs) {
      border-right: none;
      border-bottom: 1px solid var(--light-grey-line);
      height: 50%;
    }
  }

  &__body {
    flex: 1 1;
    display: flex;
    flex-direction: column;

    @media (max-width: $xs) {
      height: 50%;
    }
  }

  &-wrap {
    display: none;
    width: 100%;
    flex: 1 1;

    &--active {
      display: block;
    }
  }

  &-item {
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    width: 100%;

    .checkbox {
      margin: -10px -20px;
      padding: 10px 20px;
      width: calc(100% + 40px);
    }

    svg {
      fill: var(--grey);
    }

    &--active {
      color: var(--violet);
      background: var(--light-grey-bgr);
      text-decoration: none;
      
      svg {
        fill: var(--violet);
      }
    }

    &:hover {
      @media (min-width: $md) {
        color: var(--violet);
        background: var(--light-grey-bgr);
        text-decoration: none;
        
        svg {
          fill: var(--violet);
        }
      }
    }
  }
}