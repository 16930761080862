.like-btn {
  color: var(--grey);
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &--black {
    color: var(--black);
  }
  
  svg {
    
  }

  &__icon {
    fill: currentColor;
    width: 20px;
    height: 20px;
    display: block;

    &--fill {
      display: none;
    }
  }

  &:hover {
    @media (min-width: $md) {
      color: var(--red);
    }
  }

  &--liked {
    color: var(--red);

    .like-btn__icon {
      display: none;

      &--fill {
        display: block;
      }
    }
  }
}