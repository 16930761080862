.cc-sidebar-banner {
  &__box {
    padding: 30px 30px 0;
    text-align: center;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__desc {
    margin: 0 -5px;
    margin-bottom: 20px;
  }

  &__img {
    margin: 0 auto;
    width: 200px;
  }

  &--login {
    padding-bottom: 40px;
  }
}