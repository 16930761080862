.header-head {
  padding: 15px 0;

  &__row {
    @media (max-width: $xxl ) {
      --column-gap: 15px;
    }
    
    @media (max-width: $lg ) {
      --column-gap: 20px;
    }

    @media (max-width: $sm) {
      justify-content: space-between;
    }
  }
  

  &__col {
    display: flex;

    &--popup-search-btn {
      display: none;

      @media (max-width: $sm) {
        display: flex;
      }
    }

    &--search {
      flex: 1 1;
    }

    &--desktop,
    &--line,
    &--search {
      @media (max-width: $sm) {
        display: none;
      }
    }

    &--line,
    &--ham {
      display: none;

      @media (max-width: $lg ) {
        display: flex;
      }
    }

    &--line {
      height: 20px;
    }
  }
}