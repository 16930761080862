.create-popup {
  width: calc(100vw - 40px);
  max-width: 460px;
  min-height: 100px;
  padding: 50px 40px 40px;

  @media (max-width: $xxl ) {
    padding: 30px;
    padding-bottom: 40px;
  }

  @media (max-width: $sm ) {
    max-width: none;
    width: 100vw;
  }
}