.digits-list{
  padding-left: 0;
  list-style: none;
  display: flex;
  @media (max-width: $lg){
    flex-wrap: wrap;
  }
  &__item{
    flex: 1;
    border-right: 1px solid $grey_line;
    position: relative;
    @media (max-width: $lg){
      flex: auto;
      width: 50%;
      padding: 45px 20px;
    }
    @media (max-width: $sm){
      padding: 40px 15px;
    }
    &:last-child{
      border-right: none;
    }
    &:nth-child(even){
      @media (max-width: $lg){
        border-right: none;
      }
    }
    &:nth-child(-n+2){
      @media (max-width: $lg){
        border-bottom: 1px solid $grey_line;
      }
    }
    &-digit{
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 60px;
      line-height: 73px;
      color: $blue;
      text-align: center;
      margin-bottom: 10px;
      position: relative;
      z-index: 2;
      @media (max-width: $xxl) {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 5px;
      }
      @media (max-width: $sm){
        font-size: 26px;
        line-height: 31px;
      }
    }
    &-title{
      font-family: $Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      @media (max-width: $xxl) {
        font-size: 12px;
      }
    }
    &-icon{
      position: absolute;
      top: -20px;
      z-index: 1;
      left: calc(50% - 20px);
      @media (max-width: $lg){
        top: 20px;
      }
      svg{
        fill: $grey_line;
        @media (max-width: $xxl) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}