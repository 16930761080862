@import "social-list";
@import "contact-us-list";
@import "table-of-contents-list";
@import "my-favorites";

.entry-content {
  ul {
    list-style: none; /* Remove default bullets */
    margin-bottom: -20px;

    li {
      padding-bottom: 20px;

      &::before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: var(--blue); /* Change the color */
        font-weight: bold; /* If you want it to be bold */
        display: inline-block; /* Needed to add space between the bullet and the text */
        width: 1em; /* Also needed for space (tweak if needed) */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        font-size: 19px;
        text-align: center;
      }
    }

    @media (max-width: $xxl ) {
      margin-bottom: -15px;

      li {
        padding-bottom: 15px;
      }
    }
  }

  ol {
    counter-reset: myCounter;
    
    li{
      list-style: none;
    }
    
    li:before {
      counter-increment: myCounter;
      content:counter(myCounter);
      color: var(--black);
      background: var(--light-grey-bgr);
      display: inline-block;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      font-family: $Inter;
      border-radius: 50%;
      margin: 7.5px 15px 7.5px 0 ;
      line-height: 30px;
      width: 30px;
      height: 30px;
    }
  }
}