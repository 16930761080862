.article-banner {
  padding: 50px 60px;

  @media (max-width: $lg ) {
    padding: 60px 40px;
  }

  @media (max-width: $sm) {
    padding: 40px;
  }

  &__title {
    margin: 0 !important;
    margin-bottom: 20px !important;

    @media (max-width: $xxl ) {
      margin-bottom: 15px !important;
    }
  }

  &__desc {
    line-height: 30px;
    margin-bottom: 30px;

    @media (max-width: $xxl ) {
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
}