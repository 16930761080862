.cc-video-large-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &--reverse {
    .cc-video-large-card__box {
      grid-template-areas:
        "wrap img"
      ; 
      grid-template-columns: auto 668px;

      @media (max-width: $xxl ) {
        grid-template-columns: auto 498px;
      }
      
      @media (max-width: $lg ) {
        grid-template-columns: auto 328px;
        gap: 20px;
      }
  
      @media (max-width: $sm) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "img"
          "wrap"
        ; 
        gap: 10px;
      }
    }
  }

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    flex: 1 1;
    display: grid;
    grid-template-areas:
      "img wrap"
    ; 
    grid-template-columns: 668px auto;
    gap: calc(var(--column-gap) * 2);

    @media (max-width: $xxl ) {
      grid-template-columns: 498px auto;
    }
    
    @media (max-width: $lg ) {
      grid-template-columns: 328px auto;
      gap: 20px;
    }

    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "img"
        "wrap"
      ; 
      gap: 10px;
    }
  }

  &__wrap {
    grid-area: wrap;
    display: flex;
    flex-direction: column;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__play-btn {
    --cc-play-size: 60px;

    @media (max-width: $xxl ) {
      --cc-play-size: 40px;
    }
  }

  &__img {
    grid-area: img;

    .cc-custom-hover__img {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  &__title {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  &__desc {
    flex: 1 1;
    margin-bottom: 10px;
  }
}