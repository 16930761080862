.sidebar{
  width: 340px;
  padding-left: 20px;
  margin-top: 20px;
  border-left: 1px solid #E8E8E8;
  @media (max-width: $xxl) {
    width: 245px;
    padding-left: 15px;
  }
  @media (max-width: $lg) {
    width: 100%;
    padding-left: 0;
    border-left: none;
  }
  &-list{
    list-style: none;
    padding-left: 0;
    &__item{
      background: transparent;
      transition: all .3s ease;
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }
      &:hover,&.active{
        background: #F6F6F6;
      }
    }
    &__link{
      display: block;
      border-radius: 5px;
      padding: 15px 20px;
      font-family: $Inter;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      &--icon{
        display: flex;
        align-items: center;
        svg{
          margin-right: 10px;
          fill: $blue;
          @media (max-width: $xxl) {
            width: 16px;
            height: 16px;
          }
        }
      }
      &:hover{
        text-decoration: none;
      }
      @media (max-width: $xxl) {
        padding: 10px 15px;
        font-size: 12px;
      }
    }
  }
}