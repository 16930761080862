.favorites{
  svg{
    transition: all .3s ease;
    fill: transparent;
    stroke: $black;
  }
  @media (max-width: $sm) {
    width: 16px;
    height: 16px;
  }
  &:hover{
    svg{
      stroke: $red;
    }
  }
  &.active{
    svg{
      fill: $red;
      stroke: $red;
    }
  }
}