@import "cc";
@import "scrollbar";
@import "fields/field";
@import "btns/index";
@import "header/header";
@import "lists/index";
@import "footer";
@import "modals/index";
@import "menu/index";
@import "sliders/index";
@import "tables/index";
@import "tabs/index";
@import "effects";
@import "sections/index.scss";
@import "banners/index.scss";

.logo {
  width: 240px;
  height: 48px; 
  display: inline-block;
  background: url(../img/logo.svg) no-repeat center center / contain;

  @media (max-width: $xxl ) {
    width: 180px;
    height: 36px;
  }

  @media (max-width: $less) {
    width: 150px;
    height: 30px;
  }
}

.cc-line {
  width: 1px;
  height: 100%;
  background: var(--light-grey-line);

  &-almost {
    width: 1px;
    height: calc(100% - 40px);
    margin: 20px 0;
    background: var(--light-grey-line);

    @media (max-width: $xxl ) {
      height: calc(100% - 30px);
      margin: 15px 0;
    }

    &-down {
      width: 1px;
      height: calc(100% - 20px);
      margin-bottom: 20px;
      background: var(--light-grey-line);

      @media (max-width: $xxl ) {
        height: calc(100% - 15px);
        margin-bottom: 15px;
      }
    }
  }
}

.form-search-focus {
  --current-body-color: var(--light-grey-line);

  .footer {
    background: var(--light-grey-line);
  }
}

.link {
  &-title {
    color: inherit;
    transition: color .2s ease-in-out;

    &:hover {
      @media (min-width: $md) {
        text-decoration: none;
        color: var(--violet);
      }
    }

    &--green {
      &:hover {
        @media (min-width: $md) {
          text-decoration: none;
          color: var(--green);
        }
      }
    }
  }

  &-underline {
    text-decoration: underline;

    &:hover {
      @media (min-width: $md) {
        text-decoration: none;
      }
    }
  }

  &-noline {
    text-decoration: none !important;

    &:hover {
      @media (min-width: $md) {
        text-decoration: underline !important;
      }
    }
  }
}

.post {
  &-inf {
    display: flex;
    align-items: center;
    color: var(--grey);

    &--black {
      color: var(--black);
    }

    span {
      font-family: $Inter;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.67em;

      @media (max-width: $xs) {
        font-size: 10px;
      }
    }

    svg {
      display: block;
      margin-right: 5px;
    }
  }
}

.custom-scrollbar {
  position: relative;
}

.accordion {
  &__body {
    display: none;
  }
}

.company-masonry {
  &__item {
    width: calc(25% - 40px);

    @media (max-width: $lg ) {
      width: calc(33% - 40px);
    }

    @media (max-width: $sm) {
      width: calc(50% - 40px);
    }

    @media (max-width: $xs) {
      width: 100%;
    }
  }
}

.banner {
  width: 100%;

  &--inner-page {
    background-position: calc(100% - 40px) 100%;
    background-size: contain;

    @media (max-width: $xs) {
      background: none !important;
    }

    .banner {
      &__box {
        max-width: 300px;

        @media (max-width: $xs) {
          text-align: center;
          max-width: none;
        }
      }
    }
  }

  &--large {
    padding: 70px 80px 80px !important;

    @media (max-width: $xxl ) {
      padding: 60px 60px 80px !important;
    }

    @media (max-width: $lg ) {
      padding: 50px 40px 60px !important;
    }
    
    @media (max-width: $sm ) {
      padding: 30px 40px 40px !important;
    }
    
    @media (max-width: $extra-less ) {
      padding: 30px 20px !important;
    }
  }

  &--background {
    background-position: center bottom;
    background-size: contain;

    @media (max-width: $xxl ) {
      background-size: 330px 390px;
    }

    @media (max-width: $lg ) {
      background-image: none !important;
    }
  }

  &__login-form {
    max-width: 320px;
    display: flex;
    flex-direction: column;

    @media (max-width: $xxl ) {
      max-width: 284px;
    }
    
    @media (max-width: $sm ) {
      max-width: 400px;
    }
  }
}

.description-item {
  padding: 20px;
  border: 1px solid var(--light-grey-line);
  position: relative;
  text-align: center;

  &__title {
    color: var(--black);
  }

  @media (max-width: $xxl ) {
    padding: 15px;
  }

  @media (max-width: $sm) {
    padding: 20px;
  }

  &__header {
    display: grid;
    column-gap: 15px;

    @media (max-width: $lg ) {
      grid-template-columns: 80px 1fr;
      align-items: center;
    }

    &-content {
      @media (max-width: $lg) {
        text-align: left;
      }
    }
  }

  &__img {
    justify-self: center;
  }

  &__title {
    margin-bottom: 10px;
    margin-top: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 5px;
      margin-top: 15px;
    }

    @media (max-width: $sm) {
      margin-top: 0;
    }
  }

  &__like-btn {
    position: absolute;
    top: 20px;
    right: 20px;

    @media (max-width: $xxl ) {
      top: 15px;
      right: 15px;
    }

    @media (max-width: $sm) {
      top: 20px;
      right: 20px;
    }
  }

  &__table {
    text-align: left;
    padding: 0 10px;
    margin: 20px 0;

    @media (max-width: $xxl ) {
      margin: 15px 0;
    }

    @media (max-width: $sm) {
      margin: 20px 0;
    }

    tr {
      &:nth-child(odd) {
        td {
          background: var(--light-grey-bgr);
        }
      }
    }

    td {
      padding: 10px 10px;
    }
  }

  &__footer {
    padding: 0 20px;

    @media (max-width: $xxl ) {
      padding: 0;
    }

    @media (max-width: $lg) {
      display: grid;
      grid-template-columns: min-content min-content 1fr;
      gap: 15px; 
      align-items: center;
    }

    @media (max-width: $sm) {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }

  &__btn {
    width: 100%;
    display: flex;

    @media (max-width: $lg) {
      justify-content: flex-end;
    }

    @media (max-width: $sm) {
      justify-content: flex-start;
      margin-top: 10px;
    }

    a {
      width: 100%;

      @media (max-width: $lg) {
        width: auto;
      }
    }
  }

  &__rating {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    gap: 5px;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }

    @media (max-width: $lg ) {
      margin-bottom: 0;
    }

    @media (max-width: $sm) {
      justify-content: flex-start;
    }
  }

  &__analysis {
    display: grid;
    gap: 5px;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }

    @media (max-width: $lg ) {
      margin-bottom: 0;
      grid-template-columns: repeat(2, min-content);
    }

    @media (max-width: $sm) {
      justify-content: flex-start;
    }
  }
}

.rating-stars {
  display: inline-grid;
  grid-template-columns: repeat(5, 16px);
  gap: 5px;

  &__item {
    width: 16px;
    height: 16px;
    background: url(../img/icons/star-empty.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &--active {
      background: url(../img/icons/star.svg);
    }

    &--half {
      background: url(../img/icons/star-half.svg);
    }
  }
}

.quote {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid var(--light-grey-line);

  @media (max-width: $xxl ) {
    padding-bottom: 30px;
  }

  @media (max-width: $sm) {
    padding-bottom: 20px;
  }

  &__symbl {
    color: var(--light-grey-line);

    @media (max-width: $xxl ) {
      width: 30px;
      height: 30px;
    }
  }
}

.research-report-identify {
  display: inline-grid; 
  align-items: center;
  grid-template-columns: 1fr 40px;
  column-gap: 10px;
  grid-template-areas: 
    "name img"
    "desc img"
  ;

  @media (max-width: $xxl ) {
    grid-template-columns: 1fr 30px;
  }

  @media (max-width: $sm) {
    grid-template-columns: 30px 1fr;
    grid-template-areas: 
      "img name"
      "img desc"
    ;
  }

  &__name {
    text-align: right;
    grid-area: name;

    @media (max-width: $sm) {
      text-align: left;
    }
  }

  &__img {
    grid-area: img;
    object-fit: contain;
  }

  &__desc {
    text-align: right;
    grid-area: desc;

    @media (max-width: $sm) {
      text-align: left;
    }
  }
}

.search-results-page {
  width: 100%;
}

.error {
  &__desc {
    max-width: 680px;
  }
}

.entry-content {
  a {
    text-decoration: underline;

    &:hover {
      @media (min-width: $md) {
        text-decoration: none;
      }
    }
  }
}

.progressbar {
  --current-color: var(--green);

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &--orange {--current-color: var(--orange)}
  &--yellow {--current-color: var(--yellow)}
  &--red {--current-color: var(--red)}

  &__svg {
    width: 80px;
    height: 80px;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--current-color)
  }

  &__ring {
    stroke: var(--light-grey-line);
    stroke-dasharray: 100 0;

    &--active {
      stroke: var(--current-color);
      transform: rotate(-90deg);
      transform-origin: center;
    }
  }
}

.related-news-title {
  @media (max-width: $sm) {
    font-size: 16px; 
  }
}

@import "cards/index";