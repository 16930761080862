.header-nav {
  border-top: 1px solid var(--light-grey-line);
  border-bottom: 1px solid var(--black);
  padding: 10px 0;

  @media (max-width: $lg ) {
    display: none;
  }

  &__col {
    display: flex;

    &--main-menu {
      flex: 1 1;
    }

    &--line {
      height: 30px;
      padding: 0 90px;

      @media (max-width: $xxl ) {
        height: 20px;
        padding: 0 60px;
      }
      
      @media (max-width: $xl ) {
        padding: 0 50px;
      }
    }
  }
}