.cc-play-btn {
  --cc-play-size: 60px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--cc-play-size);
  height: var(--cc-play-size);
  color: var(--white);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--black);
    opacity: 0.5;
    transition: all .2s ease-in-out;
  }

  svg {
    width: calc(var(--cc-play-size) / 3);
    height: calc(var(--cc-play-size) / 3);
    position: relative;
    z-index: 2;
    margin-left: 3px;
  }
}