.filter-ham {
  width: 20px;
  display: grid;
  gap: 4px;

  span {
    display: block;
    height: 2px;
    width: 100%;
    background: var(--black);

    &:nth-child(2){
      width: 16px;
      justify-self: center;
    }
    &:nth-child(3){
      width: 6px;
      justify-self: center;
    }
  }
}