.confirmation-agreement {
  text-align: center;
  max-width: 520px;
  padding: 60px 40px;

  @media (max-width: $xxl ) {
    max-width: 390px;
    padding: 30px 30px 40px;
  }

  @media (max-width: $sm) {
    max-width: none;
    padding: 30px var(--column-gap) 40px;
    width: 100vw;
  }

  &__icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-grey-bgr);
    border-radius: 50%;

    @media (max-width: $xxl ) {
      height: 40px;
      width: 40px;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      @media (max-width: $xxl ) {
        margin-bottom: 15px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 27px;

    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }
  }

  &__desc {
    margin-bottom: 30px;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }
  }

  &__btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: $xxl ) {
      grid-template-columns: 1fr;
      gap: 10px; 
    }
  }
}