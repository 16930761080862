.news-article-company {
  width: 100%;
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-areas: 
    "title img"
    "desc img"
    "footer img"
  ;
  gap: 20px;
  align-items: center;
  border-top: 1px solid var(--light-grey-line);

  @media (max-width: $xxl ) {
    gap: 15px;
    padding: 30px 0;
    grid-template-columns: 1fr 180px;
  }

  @media (max-width: $lg ) {
    grid-template-columns: 1fr 135px;
  }

  @media (max-width: $sm) {
    padding: 20px 0;
    grid-template-columns: 1fr;
    grid-template-areas: 
      "img"
      "title"
      "desc"
      "footer"
    ;
  }

  &__img {
    grid-area: img;
    display: flex;
    align-items: center;

    @media (max-width: $sm) {
      max-width: 84px;
      margin-bottom: 5px;
    }
  }

  &__title,
  &__desc {
    max-width: 760px;
  }
  
  &__title {
    grid-area: title;
  }

  &__desc {
    grid-area: desc;
  }

  &__footer {
    grid-area: footer;
    display: grid;
    grid-template-columns: max-content max-content;
    gap: var(--column-gap);
    position: relative;
    z-index: 2;
    pointer-events: none;

    a {
      pointer-events: all;
    }
  }
}