.select{
  font-family: $Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #E8E8E8;
  color: $black;
  padding: 11px 15px;
  border-radius: 8px;
  &__wrap{
    position: relative;
    display: flex;
    align-items: center;
    .icon-chevron{
      position: absolute;
      right: 10px;
      transform: rotate(180deg);
    }
    select{
      -webkit-appearance: none;
    }
  }

}