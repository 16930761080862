.social-list-circle{
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  &__item{
    margin-right: 10px;
    @media (max-width: $xxl) {
      margin-right: 6px;
    }
    &:last-child{
      margin-right: 0;
    }
    &--twitter{
      &:hover{
        .social-list-circle__link{
          border-color: #1DA1F3;
        }
      }
    }
    &--linkedin{
      &:hover{
        .social-list-circle__link{
          border-color: #0076B4;
        }
      }
    }
    &--facebook{
      &:hover{
        .social-list-circle__link{
          border-color: #3F5D9A;
        }
      }
    }
    &--instagram{
      &:hover{
        .social-list-circle__link{
          border-color:  #DD2A7B;
        }
      }
    }
    &--youtube{
      &:hover{
        .social-list-circle__link{
          border-color:  #FF0000;
        }
      }
    }
  }
  &__link{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey_line;
    border-radius: 50%;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      width: 40px;
      height: 40px;
    }
    svg{
      @media (max-width: $lg) {
        width: 14px;
        height: 14px;
      }
    }
  }
}