.btn{
  &-primary{
    &--icon{
      padding: 18px 20px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      font-family: $Merr;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $xxl) {
        font-size: 12px;
        line-height: 20px;
      }
      svg{
        margin-left: 10px;
        fill: transparent;
        stroke: $white;
      }
    }
  }
  &--white{
    border: 1px solid $blue;
    color: $blue;
    border-radius: 8px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: $Inter;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      font-size: 12px;
      padding: 8px 20px;
    }
    &:hover{
      border: 1px solid $white;
      color: $white;
      background: $blue;
    }
  }
}