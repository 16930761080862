.contacts{
  padding-top: 38px;
  @media (max-width: $xxl) {
    padding-top: 30px;
  }
  @media (max-width: $sm) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  &__wrap{
    display: flex;
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
  }
  &__left{
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid $grey_line;
    @media (max-width: $xxl) {
      padding-right: 15px;
    }
    @media (max-width: $lg) {
      flex: auto;
      width: 100%;
      padding-right: 0;
      border-right: none;
    }
  }
  &__right{
    padding-left: 20px;
    flex: 1;
    @media (max-width: $xxl) {
      padding-left: 15px;
    }
    @media (max-width: $lg) {
      flex: auto;
      width: 100%;
      padding-left: 0;
    }
  }
  &__content{
    font-size: 16px;
    line-height: 30px;
    margin-top: 40px;
    @media (max-width: $xxl) {
      margin-top: 30px;
      font-size: 14px;
      line-height: 24px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
    }
  }
  &__form{
    margin-top: 49px;
    width: 100%;
    @media (max-width: $xxl) {
      margin-top: 30px;
    }
    @media (max-width: $lg) {
      margin-bottom: 40px;
    }
    @media (max-width: $sm) {
      margin-top: 20px;
    }
    .input{
      width: 100%;
      margin-bottom: 10px;
    }
    textarea{
      min-height: 120px;
      @media (max-width: $xxl) {
        min-height: 80px;
      }
    }
    button{
      margin-top: 10px;
      width: 100%;
      padding: 20px 0;
      line-height: 140%;
      @media (max-width: $lg) {
        margin-top: 5px;
      }
    }
  }
  &__images{
    position: relative;
    &-back{
      @media (max-width: $lg) {
        width: 100%;
      }
    }
    &-logo{
      position: absolute;
      top: calc(100%/2 - 44px);
      left: calc(100%/2 - 140px);
      @media (max-width: $sm) {
        width: 130px;
        height: 40px;
        top: calc(100%/2 - 20px);
        left: calc(100%/2 - 75px);
      }
    }
  }
  &__data{
    margin-top: 40px;
    border-top: 10px solid $blue_2;
    padding-top: 40px;
    display: flex;
    @media (max-width: $xxl) {
      margin-top: 30px;
      padding-top: 30px;
    }
    @media (max-width: $sm) {
      flex-wrap: wrap;
    }
    &-item{
      flex: 1;
      @media (max-width: $sm) {
        flex: auto;
        width: 100%;
        margin-bottom: 20px;
      }
      &:last-child{
        @media (max-width: $sm) {
          margin-bottom: 0;
        }
      }
      &--phone{
        svg{
          fill: $orange;
        }
      }
      &--geo{
        svg{
          fill: $blue_2;
        }
      }
      &--email{
        svg{
          fill: $green;
        }
      }
      svg{
        display: block;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: $xxl) {
          width: 24px;
          height: 24px;
        }
      }
      &__link{
        margin-top: 15px;
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
        color: $black;
        @media (max-width: $xxl) {
          font-size: 14px;
          line-height: 24px;
        }
        @media (max-width: $sm) {
          margin-top: 10px;
        }
      }
    }
  }
  &__links{
    margin-top: 40px;
    @media (max-width: $xxl) {
      margin-top: 30px;
    }
    @media (max-width: $sm) {
      margin-top: 40px;
    }
    img{
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    &-title{
      margin-top: 30px;
      text-align: center;
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 125%;
      margin-bottom: 20px;
      @media (max-width: $xxl) {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 18px;
      }
    }
  }
}