.range {
  &__field-box {
    display: flex;
    align-items: center;
    padding: 1px;
  }

  &__field {
    margin: -1px;

    &:focus {
      z-index: 1;
    }
  }

  & &__min {
    border-radius: 8px 0 0 8px;
  }

  & &__max {
    border-radius: 0 8px 8px 0;
  }

  &__slide {
    border-radius: 5px;
    border: none;
    background: var(--light-grey-line);
    height: 2px;
    left: 12px;
    width: calc(100% - 22px);

    .noUi-connect {
      background: var(--blue);
    }

    .noUi-handle {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      background: var(--blue);
      opacity: 1;
      border: none;
      box-shadow: none;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        background: var(--white);
        border: none;
        box-shadow: none;
        top: 50%;
        left: 50%;
        height: 4px;
        width: 4px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }

    &.noUi-horizontal .noUi-handle {
      right: 0;

      &.noUi-handle-upper {
        right: -10px;
      }
    }
  }
}