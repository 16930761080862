.section {
  padding: 100px 0;

  @media (max-width: $xxl) {
    padding: 80px 0;
  }

  @media (max-width: $sm) {
    padding: 60px 0;
  }

  &--banner{
    padding: 80px 0 100px 0;
    
    @media (max-width: $xxl) {
      padding: 60px 0 80px 0;
    }
    
    @media (max-width: $sm) {
      padding: 40px 0 60px 0;
    }
  } 

  &-small {
    padding-top: 20px;

    @media (max-width: $xxl ) {
      padding-top: 15px;
    }

    @media (max-width: $sm) {
      padding-top: 20px;
    }
  }
}