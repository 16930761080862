.cc-homepage-tabs-pagination {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px;
  border: 1px solid var(--light-grey-line);
  border-radius: 5px;

  &__item {
    flex: 1 1;
    padding: 2px 15px;
    text-align: center;
    border-radius: 5px;
    transition: all .2s ease-in-out;

    @media (max-width: $xxl ) {
      padding: 2px 10px;
    }

    &.swiper-pagination-bullet-active {
      background: var(--light-grey-bgr);
      cursor: default;

      &:hover {
        @media (min-width: $md) {
          color: var(--black);
        }
      }
    }
  }
}

.cc-homepage-tabs {
  max-width: 100%;
}