.advanced-data{
  padding-top: 28px;
  @media (max-width: $xxl) {
    padding-top: 15px;
  }
  &__head{
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid $black;
    @media (max-width: $xxl) {
      padding-bottom: 15px;
    }
    &-info{
      display: flex;
      align-items: center;
      &__title{
        margin-right: 20px;
        color: $black;
        @media (max-width: $xxl) {
          font-size: 12px;
          margin-right: 10px;
        }
        @media (max-width: $sm) {
          display: none;
        }
      }
      &__image{
        object-fit: contain;
        &-wrap{
          margin-right: 22px;
          width: 40px;
          min-width: 40px;
          @media (max-width: $xxl) {
            margin-right: 15px;
          }
          @media (max-width: $sm) {
            display: none;
          }
        }

      }
      .favorites{
        min-width: 16px;
      }
    }
  }
  h1{
    margin: 40px 0;
    @media (max-width: $xxl) {
      margin: 30px 0;
      font-size: 26px;
      line-height: 32px;
    }
    @media (max-width: $sm) {
      margin: 20px 0;
    }
  }
  &__menu{
    display: flex;
    list-style: none;
    padding: 5px;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    margin-bottom: 40px;
    @media (max-width: $xxl) {
      margin-bottom: 30px;
    }
    @media (max-width: $lg) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 5px;
    }
    @media (max-width: $sm) {
      grid-template-columns: 1fr;
    }
    &-item{
      flex: 1;
      border-radius: 5px;
      background: transparent;
      transition: all .3s ease;
      margin-right: 5px;
      @media (max-width: $lg) {
        flex: auto;
        margin-right: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      &.active,:hover{
        background: #F6F6F6;
      }
    }
    &-link{
      display: block;
      padding: 15px 20px;
      text-align: center;
      font-family: $Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      transition: all .3s ease;
      @media (max-width: $xxl) {
        padding: 10px 5px;
        font-size: 12px;
      }
      @media (max-width: $sm) {
        text-align: left;
        padding: 10px 20px;
      }

      &:hover{
        text-decoration: none;
      }
    }
  }
}