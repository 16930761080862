@import "hero-banner-slider";
@import "hero-slider";

.circle-slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--white);
  background: rgba(29, 29, 29, .2);
  transition: all .2s ease-in-out;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 50%;

  @media (max-width: $xxl ) {
    width: 30px;
    height: 30px;
  }

  &--prev {
    left: 20px;

    @media (max-width: $xxl ) {
      left: 15px;
    }

    @media (max-width: $lg) {
      left: 20px;
    }
  }

  &--next {
    right: 20px;

    @media (max-width: $xxl ) {
      right: 15px;
    }

    @media (max-width: $lg) {
      right: 20px;
    }
  }

  svg {
    height: 20px;
    width: 20px;

    @media (max-width: $xxl ) {
      height: 15px;
      width: 15px;
    }
  }

  &:hover {
    @media (min-width: $md) {
      &:not(.swiper-button-disabled) {
        background: rgba(29, 29, 29, .1);
      }
    }
  }

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.swiper-pagination-line {
  --cc-pagination-gap: 5px;

  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media (max-width: $xxl ) {
    bottom: 15px;
  }

  @media (max-width: $lg) {
    bottom: 10px;
  }

  .swiper-pagination-bullet {
    margin-right: 5px;
    display: block;
    background: var(--black);
    opacity: 0.2;
    border-radius: 5px;
    width: 90px;
    height: 3px;
    transition: all .2s ease-in-out;
    cursor: pointer;

    @media (max-width: $xxl ) {
      width: 60px;
      height: 2px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--white);
    opacity: 1;
    cursor: default;
  }
}

.cc-homepage-mobile-slider-news,
.cc-inner-page-news-slider,
.cc-inner-page-video-slider,
.cc-homepage-mobile-slider-video {
  overflow: visible;
}