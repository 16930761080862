.hero-banner-slider {
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    @media (max-width: $xxl ) {
      width: 100%;
      height: 206px;
      object-fit: cover;
    }

    @media (max-width: $lg) {
      height: 138px;
    }
  }
}