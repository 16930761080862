.research-reports{
  padding-top: 40px;
  @media (max-width: $xxl) {
    padding-top: 15px;
  }
  @media (max-width: $lg) {
    padding-top: 30px;
  }
  @media (max-width: $sm) {
    padding-top: 20px;
  }
  &__head{
    display: grid;
    grid-template-areas:"research-reports__head-title research-reports__head-advanced"
                        "research-reports__head-overview research-reports__head-advanced";
    grid-template-columns: 1fr 320px;
    border-bottom: 1px solid #1D1D1D;
    grid-column-gap: 40px;
    padding-bottom: 40px;
    @media (max-width: $lg) {
      grid-template-areas:"research-reports__head-title"
                          "research-reports__head-advanced"
                          "research-reports__head-overview";
      grid-template-columns: 1fr;
      padding-bottom: 0;
      border-bottom: none;
    }
    &-title{
      grid-area: research-reports__head-title;
      @media (max-width: $xxl) {
        padding-top: 15px;
        padding-bottom: 30px;
      }
      @media (max-width: $lg) {
        padding-top: 0;
        border-bottom: 1px solid $black;
      }
      @media (max-width: $sm) {
        padding-bottom: 20px;
      }
      &__current{
        display: flex;
        justify-content: space-between;
        svg{
          fill: $black;
        }
      }
      h1{
        font-weight: 300;
        margin: 20px 0;
        @media (max-width: $xxl) {
         margin: 10px 0 15px 0;
        }
      }
      &__more{
        display: flex;
        align-items: center;
        @media (max-width: $sm) {
          flex-wrap: wrap;
        }
        &-stat{
          display: flex;
          align-items: center;
          h6{
            @media (max-width: $xxl) {
              font-size: 12px;
              line-height: 20px;
            }
            @media (max-width: $sm) {
              margin-top: 3px;
            }
          }
          @media (max-width: $sm) {
            width: 100%;
            margin-bottom: 5px;
          }
          .stars{
            margin-left: 10px;
            @media (max-width: $xxl) {
              margin-left: 5px;
            }
          }
        }
        &-rating{
          font-size: 14px;
          line-height: 18px;
          margin-left: 20px;
          @media (max-width: $xxl) {
            font-size: 12px;
            margin-left: 15px;
            line-height: 20px;
          }
          @media (max-width: $sm) {
            width: 100%;
            margin-left: 0;
          }
          &-value{
            font-weight: bold;
            margin-left: 5px;
            color: $blue;
          }
        }
      }
      &__footer{
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: $lg) {
          margin-top: 15px;
        }
        @media (max-width: $sm) {
          flex-wrap: wrap;
          align-items: flex-start;
        }
        &-branch{
          font-family: $Inter;
          font-size: 12px;
          line-height: 20px;
          color: $black;
          @media (max-width: $xxl) {
            font-size: 10px;
            line-height: 16px;
          }
          @media (max-width: $sm) {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
    }
    &-advanced{
      grid-area: research-reports__head-advanced;
      @media (max-width: $lg) {
        margin-top: 15px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }
    &-overview{
      grid-area: research-reports__head-overview;
      border-top: 1px solid $grey_light;
      @media (max-width: $sm) {
        border-top: none;
      }
      p{
        @media (max-width: $xxl) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      h4{
        &:first-child{
          @media (max-width: $sm) {
            margin-top: 0;
          }
        }
      }
    }
  }
  &__body{
    padding: 40px 0 60px 0;
    display: flex;
    @media (max-width: $xxl) {
      padding: 30px 0;
    }
    @media (max-width: $lg) {
      flex-wrap: wrap;
    }
    @media (max-width: $sm) {
      padding: 40px 0;
    }
    &-content{
      flex: 1;
      padding-right: 20px;
      @media (max-width: $xxl) {
        padding-right: 15px;
      }
      @media (max-width: $lg) {
        padding-right: 0;
      }
      .sidebar{
        display: none;
        @media (max-width: $lg) {
          display: block;
          border-top: 1px solid $grey_line;
          margin-top: 0;
          padding-top: 15px;
        }
        &-list{
          @media (max-width: $lg){
            display: flex;
            flex-wrap: wrap;
          }
          &__item{
            @media (max-width: $lg){
              margin-right: 5px;
              border: 1px solid $grey_line;
              border-radius: 5px;
            }
            @media (max-width: $sm) {
              width: 100%;
              margin-right: 0;
            }
            &:last-child{
              @media (max-width: $lg){
                margin-bottom: 5px;
              }
              @media (max-width: $sm) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    &-sidebar{
      @media (max-width: $lg) {
        display: none;
      }
    }
    &-reports{
      margin-top: 40px;
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $lg){
        margin-top: 25px;
      }
      @media (max-width: $sm) {
        margin-top: 40px;
      }
    }
    &-industry{
      position: relative;
      margin-top: 40px;
      max-height: 725px;
      overflow-y: auto;
      @media (max-width: $xxl) {
        margin-top: 30px;
        max-height: 544px;
      }
      @media (max-width: $lg) {
        max-height: 488px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
        max-height: 224px;
      }
    }
    &-events{
      margin-top: 40px;
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $lg) {

      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
    &-news{
      margin-top: 40px;
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
    &-videos{
      &__list{
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 40px;
        @media (max-width: $xxl) {
          margin-top: 30px;
        }
        @media (max-width: $sm) {
          margin-top: 20px;
          grid-template-columns: 1fr;
        }
      }
      .video{
        &:before{
          position: absolute;
          right: -20px;
          top: 0;
          width: 1px;
          height: 100%;
          display: block;
          content: '';
          background: $grey_line;
          @media (max-width: $sm){
            display: none;
          }
        }
        &:after{
          position: absolute;
          left: 0;
          bottom: -20px;
          width: 100%;
          height: 1px;
          display: block;
          content: '';
          background: $grey_line;
        }
        &:nth-child(3n + 3){
          &:before{
            display: none;
          }
        }
      }
      &__more{
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
    &-seniors{
      margin-top: 40px;
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
    &-filings{
      margin-top: 40px;
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
    &-contacts{
      margin-top: 40px;
      .contacts{
        padding-top: 0;
      }
      @media (max-width: $xxl) {
        margin-top: 30px;
      }
      @media (max-width: $sm) {
        margin-top: 20px;
      }
    }
  }
}