.social-list-contacts{
  display: flex;
  list-style: none;
  padding-left: 0;
  @media (max-width: $sm){
    flex-wrap: wrap;
  }
  &__item{
    flex: 1;
    border: 1px solid $grey_line;
    transition: all .3s ease;
    margin-bottom: 10px;
    &--twitter{
      &:hover{
        border-bottom: 10px solid #1DA1F3;
      }
    }
    &--linkedin{
      &:hover{
        border-bottom: 10px solid #0076B4;
      }
    }
    &--facebook{
      &:hover{
        border-bottom: 10px solid #3F5D9A;
      }
    }
    &--instagram{
      &:hover{
        border-bottom: 10px solid #DD2A7B;
      }
    }
    &--youtube{
      &:hover{
        border-bottom: 10px solid #FF0000;
      }
    }
    @media (max-width: $sm){
      flex: auto;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    &:hover{
      margin-bottom: 0;

    }
  }
  &__link{
    padding: 35px 70px;
    display: flex;
    align-items: center;
    font-family: $Merr;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: $black;
    transition: all .3s ease;
    @media (max-width: $xxl) {
      padding: 28px 58px;
      font-size: 14px;
      line-height: 24px;
    }
    @media (max-width: $lg){
      padding: 18px 17px;
    }
    &:hover{
      text-decoration: none;
    }
    svg{
      fill: #1DA1F3;
      margin-right: 10px;
      @media (max-width: $xxl) {
        min-width: 20px;
      }
      @media (max-width: $lg){
        width: 24px;
        height: 24px;
        min-width: 24px;
      }
    }
  }
}