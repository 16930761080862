.cc-research-card {
  &__header {
    position: relative;
    z-index: 2;
    pointer-events: none;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__title {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__desc {
    margin-bottom: 20px;
    
    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }
  }

  &__img {
    width: 180px;
    height: 180px;
    padding: 20px;

    @media (max-width: $xxl ) {
      width: 105px;
      height: 105px;
    }

    @media (max-width: $lg ) {
      width: 95px;
      height: 95px;
    }

    @media (max-width: $md) {
      padding: 0;
      height: 75px;
      width: 75px;
    }
  }
}