.cc-homepage-news-card {
  position: relative;

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;

    &:hover {
      @media (min-width: $md) {
        & + .cc-homepage-news-card__box {
          .cc-homepage-news-card {
            &__title {
              color: var(--orange);
              text-decoration: underline;
            }
  
            &__img-box::after {
              border-width: 10px;
            }
          }
        }
      }
    }
  }

  &__title {
    margin: 0;
    margin-bottom: 15px;
    transition: color .2s ease-in-out;
  }

  &__img {
    @media (max-width: $sm) {
      max-height: 146px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__img-box {
    position: relative;

    @media (max-width: $sm) {
      margin-bottom: 32px;
    }
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      border: 0px solid var(--orange);
      transition: all .2s ease-in-out;
      box-sizing: border-box;
    }
  }

  &__row {
    @media (max-width: $sm) {
      flex-direction: column-reverse;
    }
  }
}