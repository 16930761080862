.cc-homepage-company-card {
  @media (max-width: $xs) {
    border-bottom: 1px solid var(--light-grey-line);
  }

  &__heading {
    --cc-homepage-company-card-icon-size: 20px;

    display: grid;
    grid-template-columns: var(--cc-homepage-company-card-icon-size) auto;
    align-items: center;
    gap: 5px;
    
    @media (max-width: $xs) {
      padding-bottom: 10px;
    }

    span {
      white-space: nowrap;
    }

    svg {
      fill: var(--blue);
      transition: transform .2s ease-in-out;
      display: block;
      width: var(--cc-homepage-company-card-icon-size);
      height: var(--cc-homepage-company-card-icon-size);
    }

    &:hover {
      @media (min-width: $md) {
        span {
          color: var(--blue);
        }
      }
    }
  }

  &__list {
    list-style: none;
    padding-left: 25px;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  &.accordion--active {
    .cc-homepage-company-card {
      &__heading {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__link {
    &:hover {
      color: var(--blue) !important;
    }
  }
}

.company-masonry {
  &__item {
    &:last-child {
      .cc-homepage-company-card {
        @media (max-width: $xs) {
          border-bottom: none;
        }
      }
    }
  }
}