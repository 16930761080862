.how{
  @media (max-width: $xxl) {
    padding-bottom: 0;
  }
  @media (max-width: $sm){
    padding-bottom: 0;
  }
  &__head{
    display: flex;
    align-items: center;
    @media (max-width: $sm){
      display: block;
    }
    .count--number-page{
      @media (max-width: $sm){
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-title{
      margin-left: 20px;
      @media (max-width: $sm){
        margin-left: 0;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
  &__slider{
    position: relative;
    padding-top: 130px;
    margin-top: -50px;
    padding-left: var(--container-side-padding);
    margin-left: calc((100% - (var(--container-width) + var(--container-side-padding)*2))/2);
    @media (max-width: $xxl) {
      margin-top: -40px;
      padding-top: 120px;
    }
    @media (max-width: $lg){
      margin-top: -40px;
      padding-top: 100px;
    }
    @media (max-width: $sm){
      margin-left: 0;
      margin-top: 0;
      padding-top: 20px;
    }
    &-item{
      max-width: 440px;
      padding: 70px 60px 90px 60px;
      border: 1px solid $grey_line;
      border-top: 10px solid $grey_line;
      color: $black;
      transition: all .3s ease;
      height: auto;
      position: relative;
      &:after{
        position: absolute;
        left: 0;
        top: -10px;
        width: calc(100% - 20px);
        height: calc(100% - 10px);
        content: '';
        display: block;
        border: 10px solid $yellow;
        transition: all .3s ease;
        opacity: 0;
      }
      @media (max-width: $xxl) {
        max-width: 340px;
        padding: 30px 30px 54px 30px;
      }
      @media (max-width: $sm){
        max-width: 240px;
        padding: 20px;
      }
      &:hover{
        text-decoration: none;
        &:after{
          opacity: 1;
        }
        @media (max-width: $sm){
          max-width: 258px;
        }
      }
      &__icon{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        @media (max-width: $xxl) {
          width: 40px;
          height: 40px;
          margin-bottom: 20px;
        }
        svg{
          fill: $white;
          @media (max-width: $xxl) {
            width: 20px;
            height: 20px;
          }
        }
      }
      &__title{
        margin-bottom: 20px;
        @media (max-width: $xxl) {
          font-size: 20px;
          line-height: 25px;
        }
      }
      &__text{
        font-size: 16px;
        line-height: 30px;
        @media (max-width: $xxl) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .slider__arrow{
      position: absolute;
      top: 0;
      right: calc((100% - (var(--container-width) + var(--container-side-padding)*2))/2);
      @media (max-width: $sm){
        display: none;
      }
      &.swiper-button-prev{
        right: calc(((100% - (var(--container-width) + var(--container-side-padding)*2))/2) + 70px);
        @media (max-width: $xxl) {
          right: calc(((100% - (var(--container-width) + var(--container-side-padding)*2))/2) + 50px);
        }
      }
    }
    .swiper-scrollbar{
      height: 1px;
      background: $grey_line;
      margin-top: 80px;
      @media (max-width: $xxl) {
        margin-top: 60px;
      }
      &-drag{
        background: $blue;
        height: 2px;
      }
    }
  }
}