.hero-slider {
  position: relative;

  &__wrap {
    width: calc(100% - 81px);
    margin-left: 0;
  }

  &__nav {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__line {
    margin-left: 20px;
    margin-right: 20px;
  }
}