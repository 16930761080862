.scale {
  overflow: hidden;

  &__img {
   transition: all .4s ease-in-out; 
  }

  &:hover {
    .scale__img {
      @media (min-width: $md) {
        transform: scale(1.1);
        transition: all .5s linear; 
      }
    }
  }
}

.cc-custom-hover {
  --cc-custom-hover-current-color: var(--violet);
  --cc-custom-hover-size: 10px;

  position: relative;

  &--turquoise {
    --cc-custom-hover-current-color: var(--turquoise);
  }
  
  &--orange {
    --cc-custom-hover-current-color: var(--orange);
  }
  
  &--blue {
    --cc-custom-hover-current-color: var(--blue);
  }

  &__img {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: unset 0px solid var(--cc-custom-hover-current-color);
      transition: border-width .2s ease-in-out;
      box-sizing: border-box; 
    }
  } 

  &__text {
    color: var(--black);
    transition: color .2s ease-in-out; 
    text-decoration: none;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &--small {
    --cc-custom-hover-size: 5px;
  }

  &--large {
    --cc-custom-hover-size: 15px;
  }
  
  &--extra-less {
    --cc-custom-hover-size: 2px;

    .cc-custom-hover__img {
      &::after {
        transition: border-width .0s ease-in-out;
      }
    }
  }

  &--active {
    @media (min-width: $md) {
      .cc-custom-hover__text {
        color: var(--cc-custom-hover-current-color);
        text-decoration: underline;
      }

      .cc-custom-hover__img {
        &::after {
          border-width: var(--cc-custom-hover-size);
        }
      }
    }
  }
}