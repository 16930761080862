.cc-video-small-card {
  flex: 1 1;
  display: flex;
  flex-direction: column;

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    flex: 1 1;
    display: grid;
    grid-template-areas: 
      "title img"
      "desc img"
      "footer footer"
    ;
    grid-template-columns: auto 120px;
    column-gap: 20px; 

    @media (max-width: $xxl ) {
      column-gap: 10px; 
      grid-template-columns: auto 60px;
    }

    @media (max-width: $lg ) {
      grid-template-columns: 1fr;
      grid-template-areas:
        "img" 
        "title"
        "desc"
        "footer"
      ;
      gap: 10px;
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__play-btn {
    --cc-play-size: 30px;

    @media (max-width: $xxl ) {
      --cc-play-size: 16px;
    }

    @media (max-width: $lg ) {
      --cc-play-size: 40px;
    }
  }

  &__img {
    grid-area: img;

    .cc-custom-hover__img {
      width: 100%;

      @media (max-width: $lg ) {
        margin: 0;
        padding: 0;
      }
    }

    img {
      max-height: 187px;
      width: 100%;
    }
  }

  &__title {
    grid-area: title;
    margin: 0 !important; 
    margin-bottom: 10px !important;

    @media (max-width: $lg ) {
      margin-bottom: 0 !important;
    }
  }

  &__desc {
    grid-area: desc;
    margin-bottom: 20px;

    @media (max-width: $xxl ) {
      margin-bottom: 10px;
    }

    @media (max-width: $lg ) {
      margin-bottom: 0;
    }
  }

  &__footer {
    grid-area: footer;
  }
}