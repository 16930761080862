.current{
  &-page{
    font-family: $Inter;
    font-weight: 500;
    color: $blue;
    font-size: 14px;
    line-height: 20px;
    @media (max-width: $xxl) {
      font-size: 12px;
    }
    @media (max-width: $sm) {
      text-align: center;
    }
  }
}