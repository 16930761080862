#table-of-contents-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.table-of-contents-link {
  border-radius: 5px;
  padding: 15px 20px;
  color: var(--black);
  transition: background .2s ease-in-out;
  font-family: $Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @media (max-width: $xxl ) {
    font-size: 12px;
    padding: 10px 15px;
  }

  &:hover, &--active {
    background: var(--light-grey-bgr);
    text-decoration: none;
  }
}

.tof-wrap {
  flex: 1 1;
}