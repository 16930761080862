.cc-my-favorites-list {
  list-style: none;
  padding: 0;
  display: inline-grid;
  grid-template-columns: 1fr;
  gap: 5px;
  width: 100%;

  &__link {
    padding: 15px 20px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 20px 1fr max-content;
    gap: 10px;

    &--blue{color: var(--blue)}
    &--orange{color: var(--orange)}
    &--violet{color: var(--violet)}
    &--turquoise{color: var(--turquoise)}

    &:hover {
      @media (min-width: $md) {
        background: var(--light-grey-bgr);
        text-decoration: none;
      }
    }

    &--active {
      background: var(--light-grey-bgr);
    }
  }

  &__title {
    color: var(--black);
  }
}