.entry-content-banner {
  padding: 40px;
  border: 1px solid var(--light-grey-line);
  border-top: 10px solid var(--violet);
  position: relative;
  overflow: hidden;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    color: var(--black);
    transition: color .2s ease-in-out;

    &:hover {
      @media (min-width: $md) {
        color: var(--grey);
      }
    }
  }

  &--dark {
    border: 1px solid var(--black);
    border-top: 10px solid var(--blue);
    min-height: 280px;
    display: flex;
    align-items: center;

    .entry-content-banner__img {
      @media (max-width: $lg ) {
        display: block;
        transform: translateX(-65%);
      }
    }
  }

  @media (max-width: $xxl ) {
    padding: 30px;
  }

  @media (max-width: $lg ) {
    padding: 30px 40px 40px;
  }

  @media (max-width: $sm) {
    padding: 30px 20px;
    padding-bottom: 0;
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    object-fit: contain;
    z-index: -1;

    @media (max-width: $xxl ) {
      max-height: calc(100% - 40px);
      transform: translateX(-55%);
    }

    @media (max-width: $lg ) {
      display: none;
    }

    @media (max-width: $sm) {
      position: relative;
      left: auto;
      bottom: auto;
      transform: none;
      margin: 0 auto;
      margin-top: 40px;
      max-width: 200px;
      width: 100%;
      display: block;
    }
  }
}