.cc-movers-table-card {
  padding: 20px;
  border-bottom: 1px solid var(--light-grey-line);
  transition: background .1s linear;
  margin-bottom: -1px;

  @media (max-width: $xxl ) {
    padding: 20px 15px;
  }

  @media (max-width: $sm) {
    padding: 20px 0;
    border-top: 1px solid var(--light-grey-line);
  }

  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: grid;
    grid-template-columns: var(--cc-movers-ratio);
    grid-template-areas: "title desc price ratio like";
    gap: 5px;
    align-items: center;

    @media (max-width: $sm) {
      grid-template-areas: "title desc like";
      column-gap: 15px;
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__title {
    color: var(--blue);
    text-decoration: underline;
    grid-area: title;

    @media (max-width: $sm) {
      margin-bottom: 5px;
      grid-area: auto;
      grid-column: 1/4;
    }
  }

  &__like-wrap {
    grid-area: like;

    @media (max-width: $sm) {
      grid-area: auto;
      grid-column: 3;
    }
  }

  &__desc {
    grid-area: desc;

    @media (max-width: $sm) {
      grid-area: auto;
      grid-column: 2/4;
    }
  }

  &__price {
    grid-area: price;

    @media (max-width: $sm) {
      grid-area: auto;
      grid-column: 2/4;
    } 
  }

  &__ratio {
    grid-area: ratio;

    @media (max-width: $sm) {
      grid-area: auto;
      grid-column: 2/2;
    }
  }

  &.cc-custom-hover--active {
    @media (min-width: $md) {
      background: var(--light-grey-bgr);

      .cc-movers-table-card__title {
        text-decoration: none;
      }
    }
  }
}