.cc-calendar-card {
  position: relative;

  &__date {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--light-grey-bgr);
    border-radius: 8px;

    span {
      display: block;

      span {
        &:first-child {
          margin-bottom: -3px;
        }
      }
    }

    @media (max-width: $xxl ) {
      height: 35px;
      width: 35px;
    }
  }

  &__title {
    margin-bottom: 5px;
    transition: color .2s ease-in-out;

    @media (max-width: $xxl ) {
      margin-bottom: 0px;
    }
  }

  &__icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: var(--light-grey-bgr);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;

    @media (max-width: $xxl ) {
      height: 30px;
      width: 30px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:hover {
      @media (min-width: $md) {
        & + .cc-calendar-card__box .cc-calendar-card__title {
          color: var(--violet);
        }
        
        & + .cc-calendar-card__box .cc-calendar-card__icon {
          color: var(--white);
          background: var(--blue);
        }
      }
    }
  }
}