.note{
  padding-top: 20px;
  @media (max-width: $xxl) {
    padding-top: 40px;
    padding-bottom: 80px;
  }
  @media (max-width: $lg) {
    padding-top: 60px;
  }
  @media (max-width: $sm) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  &__title{
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 75px;
    @media (max-width: $xxl) {
      font-size: 40px;
      line-height: 50px;
    }
    @media (max-width: $sm) {
      font-size: 26px;
      line-height: 33px;
    }
  }
  &__content{
    margin-top: 40px;
    font-size: 16px;
    line-height: 30px;
    @media (max-width: $xxl) {
      margin-top: 20px;
      line-height: 24px;
    }
    @media (max-width: $sm) {
      font-size: 14px;
    }
  }
  .indicators__wrap{
    @media (max-width: $xxl) {
      padding: 50px;
    }
    @media (max-width: $lg) {
      padding: 30px;
    }
    @media (max-width: $sm) {
      padding: 35px 20px 20px 20px;
    }
  }
}