.cc-repated-research-report-card {
  &__box {
    position: relative;
    z-index: 2;
    pointer-events: none;
    display: grid;
    grid-template-columns: 1fr 75px;
    grid-template-areas: 
      "header img"
      "body img"
      "footer footer"
    ;
    column-gap: 15px;
    row-gap: 10px;

    @media (max-width: $xxl ) {
      grid-template-columns: 1fr 55px;
    }
  }

  &__like-btn {
    pointer-events: all;
  }

  &__header {
    grid-area: header;
  }

  & &__img {
    grid-area: img;

    .cc-custom-hover__img {
      @media (min-width: $md) {
        padding: 10px;
      }
    }

    img {
      width: 100%;
    }
  }

  &__body {
    grid-area: body;
  }

  &__footer {
    grid-area: footer;
  }

  &__title {
    margin-bottom: 10px;
  }
}