.cc-channelchek-large-card {
  position: relative;
  display: flex;
  flex-direction: column; 
  flex: 1 1;

  &__header {
    position: relative;
    z-index: 2;
    pointer-events: none;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (max-width: $xxl ) {
      margin-top: 10px;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }

  &__footer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  &__like-btn {
    pointer-events: all;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__desc {
    margin-bottom: 20px;
    flex: 1 1;

    @media (max-width: $xxl ) {
      margin-bottom: 15px;
    }

    @media (max-width: $xs) {
      margin-bottom: 10px;
    }
  }
}