.popular-videos{
  padding-top: 60px;
  @media (max-width: $xxl){
    padding-top: 60px;
  }
  @media (max-width: $sm){
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 40px;
    margin-top: 80px;
    @media (max-width: $xxl){
      margin-top: 30px;
      gap: 30px;
    }
    @media (max-width: $lg){
      grid-template-columns: repeat(3,1fr);
    }
    @media (max-width: $sm){
      grid-template-columns: 1fr;
      margin-top: 40px;
    }
    .video{
      @media (max-width: $lg){
        display: none;
      }
      @media (max-width: $sm){
        border-bottom: 1px solid $grey_line;
        padding-bottom: 20px;
      }
      &:nth-child(-4n+4){
        &:after{
          display: none;
        }
      }
      &:after{
        position: absolute;
        right: -20px;
        top: 0;
        width: 1px;
        height: 100%;
        display: block;
        content: '';
        background: $grey_line;
        @media (max-width: $xxl){
          right: -15px;
        }
        @media (max-width: $sm){
          display: none;
        }
      }
      &:nth-child(-n+3){
        @media (max-width: $lg){
          display: block;
        }
      }
      &:nth-child(-3n+3){
        &:after{
          @media (max-width: $lg){
            display: none;
          }
        }
      }
    }
  }
  &__view{
    margin-top: 80px;
    display: flex;
    justify-content: center;
    @media (max-width: $xxl){
      margin-top: 40px;
    }
    @media (max-width: $sm){
      margin-top: 20px;
    }
    .btn{
      line-height: 20px;
      padding: 20px 77px;
      @media (max-width: $xxl){
        padding: 12px 52px;
      }
      @media (max-width: $sm){
        line-height: 17px;
      }
      svg{
        margin-left: 10px;
      }
    }
  }
}